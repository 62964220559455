
  <div class="payment-history-wrap" [class.mobile]="deviceService.isMobile()">
    <div class="title-wrap">
      <app-transactions-filter></app-transactions-filter>
    </div>

    <div [formGroup]="form" class="filter">
      <app-filter-content [tabs]="tabs" [activeTab]="selectedTab" (onSelect)="goTab($event)"></app-filter-content>

      <on-ui-date formControlName="date"
                  [startView]="'year'"
                  [inputDisplayed]="false"
                  [onlyYearAndMonth]="true"
                  (onMonthSelect)="onChangedDate($event)">
        <div class="view-date">
          {{'transaction-history.operations-for' | translate}} {{form.get('date').value | dfnsFormat: 'LLLL'}}
          <app-icon class="btn-arrow-ico" width="11" height="6" icon="angle-arrow"></app-icon>
        </div>
      </on-ui-date>

      <div class="search-wrap" [class.on-focus]="isFocus">
        <app-icon class="search-content-icon" icon="search"></app-icon>
        <input type="text"
               class="search-input"
               formControlName="search_term"
               (focus)="onFocus()"
               (blur)="onFocus()"
               [placeholder]="'transaction-history.search-placeholder' | translate"/>
        <app-icon width="12" height="12" class="search-icon-close"
                  [class.visible]="!!form.get('search_term').value"
                  (click)="clearSearchForm()"
                  icon="close-search">
        </app-icon>
      </div>
    </div>
    <ng-container *ngIf="transactions.loaded">
      <ng-template [ngIf]="transactions.items.length" [ngIfElse]="noContentStub">
        <div class="history-wrap">
          <app-payment-history [history]="transactions.items"></app-payment-history>
        </div>
        <div class="paginator-wrap" [class.mobile]="deviceService.isMobile()">
          <app-paginator [pagination]="pagination" (pageChanged)="loadPageTransaction($event)"></app-paginator>
        </div>
      </ng-template>
    </ng-container>


    <ng-template #noContentStub>
      <app-no-content-stub
        [icon]="'stub-billing-history'"
        [text]="'subscription-plans.stub-billing-history'">
      </app-no-content-stub>
    </ng-template>
  </div>


