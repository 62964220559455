import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { loadMe } from '@app/store/actions/profile.actions';
import { LoadingBarService } from '@core/components/spinners/loading-bar/loading-bar.service';
import {
  BillingService,
  ContextService,
  MetaService,
  BillingOperation,
  LanguageService,
  CollectionsService,
  FeaturesService,
  AccountService,
} from '@core/services';
import { BillingV2Service } from '@core/services/lingo2-account/billing-v2.service';
import {
  BillingApiTransactionFindQueryDto,
  BillingApiTransactionResponseDto,
  BillingApiContractorDto,
} from '@lingo2-billing-sdk/models';
import { BillingCoreTransactionTypeEnum } from '@lingo2-billing-sdk/models/src/billing-core/billing-core-account/types/enums/billing-core-transaction-type.enum';
import { ChangableComponent } from '@models/changable.component';
import { Store } from '@ngrx/store';
import { ErrorNotificationService } from '@shared/error-notification/error-notification.service';
import { loadCollection, loadMeeting, loadUserService } from '@store/actions/content.actions';
import { loadFilteredLanguages } from '@store/actions/data.actions';
import { IMeetingCollection } from '@store/models';
import { format } from 'date-fns';
import { enGB } from 'date-fns/locale';
import ru from 'date-fns/locale/ru';
import {
  User,
  Language,
  UserService,
  Collection,
  IBillingCard,
  IFindBillingOperationFilter,
  IContentBlock,
  currencies,
  CurrencyEnum,
  CReceipt,
  BillingOperationStatusEnum,
  BillingOperationEnum,
  AccountBalanceTypeEnum,
  BillingTransaction,
  BillingContractorDescriptorEnum,
} from 'lingo2-models';
import { IPagination } from 'lingo2-models/dist/interfaces';
import { DeviceDetectorService } from 'ngx-device-detector';
import { map } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';

interface HistoryTab {
  id: number;
  code: string;
  title: string;
}

/**
 * OLD HISTORY PAGE
 */
interface FilteredOperations {
  date: string;
  operations: BillingOperation[];
}

/**
 * OLD HISTORY PAGE
 */

@Component({
  selector: 'app-finance-history-page',
  templateUrl: './finance-history-page.component.html',
  styleUrls: ['./finance-history-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FinanceHistoryPageComponent extends ChangableComponent implements OnInit, OnDestroy {
  @Input() schoolId: string;

  public me: User;
  private currentLanguage: Language;

  public isFocus: boolean;
  public form: UntypedFormGroup;
  public tabs: HistoryTab[] = [
    {
      id: 1,
      code: 'all',
      title: 'transaction-history.tabs.all',
    },
    {
      id: 2,
      code: 'credit',
      title: 'transaction-history.tabs.credit',
    },
    {
      id: 3,
      code: 'debit',
      title: 'transaction-history.tabs.debit',
    },
  ];

  public selectedTab: HistoryTab = this.tabs[0];

  public pagination: IPagination = {
    page: 1,
    pageSize: 15,
  };
  public transactions: Partial<IContentBlock<BillingApiTransactionResponseDto, BillingApiTransactionFindQueryDto>> = {
    loading: false,
    loaded: false,
    items: [],
    filter: {},
  };

  /**
   * OLD HISTORY PAGE
   */
  public operations: IContentBlock<BillingOperation, IFindBillingOperationFilter> = {
    loading: false,
    loaded: false,
    items: [],
    filter: {},
    pagination: {
      page: 1,
      total: 0,
      totalPages: 0,
      pageSize: 10,
    },
  };
  public filteredOperations: FilteredOperations[];
  public activeOperation: BillingOperation;
  public selectedOperation: BillingOperation;
  public operationDetailModalOpen = false;

  private userServices: UserService[] = [];
  private collections: Collection[] = [];
  private meetingsCollection: IMeetingCollection;
  private plans: any;
  private cards: IBillingCard[];
  private locales = { enGB, ru };

  /**
   * OLD HISTORY PAGE
   */

  public constructor(
    public errorNotificationService: ErrorNotificationService,
    private features: FeaturesService,
    private billingService: BillingService,
    private billingV2Service: BillingV2Service,
    private contextService: ContextService,
    private languageService: LanguageService,
    private collectionsService: CollectionsService,
    private loadingBar: LoadingBarService,
    protected fb: UntypedFormBuilder,
    private readonly store: Store,
    private meta: MetaService,
    protected readonly cdr: ChangeDetectorRef,
    public deviceService: DeviceDetectorService,
  ) {
    super(cdr);
  }

  public ngOnInit() {
    this.contextService.me$.pipe(takeUntil(this.destroyed$)).subscribe({
      next: (me) => {
        this.me = me;
        this.contextService.updateUser(me); // чтобы сайдар корректно отображал меню текущего пользователя
        this.detectChanges();
      },
      error: (error) => {
        this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
        this.store.dispatch(loadMe());
      },
    });

    this.languageService.language$.pipe(takeUntil(this.destroyed$)).subscribe({
      next: (language) => {
        this.currentLanguage = language;
        this.detectChanges();
      },
      error: (error) => {
        this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
        this.store.dispatch(loadFilteredLanguages({ locale: this.me.ui_language }));
      },
    });

    this.form = this.fb.group({
      search_term: [''],
      date: [new Date()],
    });

    this.goTab(this.tabs[0], true);
  }

  public onFocus() {
    this.isFocus = !this.isFocus;
  }

  public clearSearchForm() {
    this.form.get('search_term').setValue('');
  }

  public onChangedDate(e: Date) {
    this.form.get('date').setValue(new Date(e));
    this.pagination.page = 1;
    this.loadTransactions();
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
    this.meta.reset();
  }

  public loadTransactions() {
    const date = this.form.get('date').value;
    const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
    const lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    const request = new BillingApiTransactionFindQueryDto();
    request.type = this.transactions.filter.type;
    request.createdAtFrom = firstDayOfMonth;
    request.createdAtTo = lastDayOfMonth;
    request.page = this.pagination.page;
    request.pageSize = this.pagination.pageSize;
    request.chargeMeta = { tenant: this.features.tenant };

    const contractor = new BillingApiContractorDto();

    if (this.schoolId) {
      contractor.descriptor = BillingContractorDescriptorEnum.school;
      contractor.externalId = this.schoolId;
    }

    this.billingV2Service
      .findTransactions(request, contractor)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (res) => {
          this.transactions.items = res.results;
          this.transactions.loaded = true;
          this.pagination = res.pagination;
          this.detectChanges();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
        },
      });
  }

  public goTab(tab: HistoryTab, force?: boolean) {
    if (tab.code === this.selectedTab.code && !force) {
      return;
    }
    this.selectedTab = tab;

    switch (tab.code) {
      case 'credit':
        this.transactions.filter.type = this.schoolId
          ? [BillingCoreTransactionTypeEnum.PURCHASE] // Выкупили];
          : [
              BillingCoreTransactionTypeEnum.PURCHASE, // Выкупили
              BillingCoreTransactionTypeEnum.SELLER_REFUND, // Вы вернули
            ];
        break;
      case 'debit':
        this.transactions.filter.type = this.schoolId
          ? [
              BillingCoreTransactionTypeEnum.BROKER_PAYMENT_RECEIVED, // У вас купили
              BillingCoreTransactionTypeEnum.BROKER_PREPAYMENT_RECEIVED, // У вас купили(безопасная сделка)
              BillingCoreTransactionTypeEnum.REFUND_PURCHASE, // Возврат покупки
              BillingCoreTransactionTypeEnum.PAYOUT, // Выплата
            ]
          : [
              BillingCoreTransactionTypeEnum.SELLER_PAYMENT_RECEIVED, // У вас купили
              BillingCoreTransactionTypeEnum.SELLER_PREPAYMENT_RECEIVED, // У вас купили(безопасная сделка)
              BillingCoreTransactionTypeEnum.REFUND_PURCHASE, // Возврат покупки
              BillingCoreTransactionTypeEnum.PAYOUT, // Выплата
            ];
        break;
      case 'all':
        this.transactions.filter.type = this.schoolId
          ? [
              BillingCoreTransactionTypeEnum.PURCHASE,
              BillingCoreTransactionTypeEnum.BROKER_PAYMENT_RECEIVED,
              BillingCoreTransactionTypeEnum.BROKER_PREPAYMENT_RECEIVED,
              BillingCoreTransactionTypeEnum.REFUND_PURCHASE,
              BillingCoreTransactionTypeEnum.PAYOUT,
            ]
          : [
              BillingCoreTransactionTypeEnum.PURCHASE,
              BillingCoreTransactionTypeEnum.SELLER_PREPAYMENT_RECEIVED,
              BillingCoreTransactionTypeEnum.SELLER_PAYMENT_RECEIVED,
              BillingCoreTransactionTypeEnum.REFUND_PURCHASE,
              BillingCoreTransactionTypeEnum.SELLER_REFUND,
              BillingCoreTransactionTypeEnum.PAYOUT,
            ];
        break;
    }
    this.loadTransactions();
  }

  // функция для трекинга списка, чтобы он не 'моргал'
  public trackByFn(index, item: BillingOperation): string {
    return item.id;
  }

  /**
   * OLD HISTORY PAGE
   */
  protected updateMeetings() {
    this.operations.items.forEach((operation) => {
      if (operation.meeting_id && !operation.meeting) {
        operation.meeting = this.meetingsCollection[operation.meeting_id];
      }
    });
  }

  protected updateUserServices() {
    this.operations.items.forEach((operation) => {
      if (operation.user_service_id && !operation.userService) {
        operation.userService = this.userServices.find((s) => s.id === operation.user_service_id);
      } else if (operation.details && operation.details.user_service_id && !operation.userService) {
        operation.userService = this.userServices.find((s) => s.id === operation.details.user_service_id);
      }
    });
  }

  protected updateCollections() {
    this.operations.items.forEach((operation) => {
      if (operation.details && operation.details.collection_id && !operation.collection) {
        operation.collection = this.collections.find((c) => c.id === operation.details.collection_id);
      }
    });
  }

  protected loadHistory(nextPage?: number) {
    this.operations.loading = true;
    this.filteredOperations = [];
    this.loadingBar.fetchingStart(35);
    if (typeof nextPage === 'number' && nextPage <= this.operations.pagination.totalPages) {
      this.operations.pagination.page = nextPage;
    }

    if (this.schoolId) {
      this.operations.filter.school_id = this.schoolId;
    }

    this.billingService
      .findOperations(this.operations.filter, this.operations.pagination)
      .pipe(first())
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (response) => {
          this.operations.items = this.filterOperations(response.results);
          this.updateMeetings();
          this.updateUserServices();
          this.updateCollections();
          this.operations.pagination.total = response.total;
          this.operations.pagination.totalPages = response.totalPages;
          this.operations.pagination.page = response.page;
          this.operations.loaded = true;
          this.operations.loading = false;
          this.loadingBar.fetchingComplete();
          this.detectChanges();
        },
        error: (error: any) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
          this.operations.loading = false;
          this.detectChanges();
          this.loadingBar.fetchingStop();
        },
      });
  }

  protected filterOperations(operations: BillingOperation[]) {
    operations.forEach((operation) => {
      if (operation.meeting_id) {
        this.store.dispatch(loadMeeting({ meeting_id: operation.meeting_id }));
      } else if (operation.user_service_id) {
        this.store.dispatch(loadUserService({ user_service_id: operation.user_service_id }));
      } else if (operation.details.user_service_id) {
        this.store.dispatch(loadUserService({ user_service_id: operation.details.user_service_id }));
      } else if (operation.details.collection_id) {
        this.store.dispatch(loadCollection({ collection_id: operation.details.collection_id }));
        this.collectionsService
          .getById(operation.details.collection_id, ['title'])
          .pipe(map((el) => el.title))
          .pipe(takeUntil(this.destroyed$))
          .subscribe({
            next: (data) => {
              operation.collection = { title: data };
              this.detectChanges();
            },
            error: (error) => {
              this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
            },
          });
      }
      const ifExistThisDate =
        this.filteredOperations.filter((item) => item.date === this.formatDateForFilter(operation.created_at)).length >
        0;
      if (this.isToday(operation.created_at, new Date())) {
        if (this.filteredOperations.find((op) => op.date === 'today')) {
          this.filteredOperations.find((op) => op.date === 'today').operations.push(operation);
        } else {
          this.filteredOperations.push({
            date: 'today',
            operations: [operation],
          });
        }
      } else if (this.isYesterday(operation.created_at, new Date())) {
        if (this.filteredOperations.find((op) => op.date === 'yesterday')) {
          this.filteredOperations.find((op) => op.date === 'yesterday').operations.push(operation);
        } else {
          this.filteredOperations.push({
            date: 'yesterday',
            operations: [operation],
          });
        }
      } else {
        if (ifExistThisDate) {
          this.filteredOperations.forEach((fOperation) => {
            if (fOperation.date === this.formatDateForFilter(operation.created_at)) {
              fOperation.operations.push(operation);
            }
          });
        } else {
          this.filteredOperations.push({
            date: this.formatDateForFilter(operation.created_at),
            operations: [operation],
          });
        }
      }
    });
    return operations;
  }

  protected isToday(date1: Date, currentDate: Date) {
    return (
      date1.getFullYear() === currentDate.getFullYear() &&
      date1.getMonth() === currentDate.getMonth() &&
      date1.getDate() === currentDate.getDate()
    );
  }

  protected isYesterday(date1: Date, currentDate: Date) {
    if (date1.getFullYear() === currentDate.getFullYear() && date1.getMonth() === currentDate.getMonth()) {
      return date1.getDate() - currentDate.getDate() === -1;
    } else {
      return false;
    }
  }

  protected formatDateForFilter(date: Date) {
    const newDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    return format(newDate, 'dd MMMM yyyy', { locale: this.formatLocale });
  }

  protected get formatLocale() {
    return this.currentLanguage.code === 'ru' ? this.locales.ru : this.locales.enGB;
  }

  public loadPage(page: number) {
    this.loadHistory(page);
  }

  public loadPageTransaction(page: number) {
    this.pagination.page = page;
    this.loadTransactions();
  }

  public getMeetingUserCount(operation: BillingOperation) {
    if (!operation.meeting) {
      return;
    }
    return operation.meeting.classroom?.participants ? operation.meeting?.classroom?.participants.length : null;
  }

  public getCardName(card_id: string) {
    const card = this.cards.find((c) => c.id === card_id);
    return card?.title ? card?.title : '';
  }

  public getOperationName(operation: BillingOperation) {
    if (operation.user_service_id || operation.details.user_service_id) {
      if (!operation.userService) {
        return 'payments-history.no-data-available';
      }
      return operation.userService.title;
    } else if (operation.meeting_id) {
      if (!operation.meeting) {
        return 'payments-history.no-data-available';
      }
      return operation.meeting.title;
    } else if (operation.plan_id) {
      const plan = this.plans?.find((s) => s.id === operation.plan_id);
      return plan?.details?.title ? plan?.details?.title : '';
    } else if (operation.details.collection_id) {
      if (!operation.collection) {
        return 'payments-history.no-data-available';
      }
      return operation.collection.title;
    }
    //  else if (operation.billing_card && operation.operation === BillingOperationEnum.safe_card_attach) {
    //   if (!operation.billing_card.title) {
    //     return 'payments-history.no-data-available';
    //   }
    //   return operation.billing_card.title;
    // }
    else {
      return 'payments-history.no-data-available';
    }
  }

  public isOperationState(operation: BillingOperation, state): boolean {
    switch (state) {
      case 'done':
        return operation.status === BillingOperationStatusEnum.succeed;

      case 'failed':
        return (
          operation.status === BillingOperationStatusEnum.failed ||
          operation.status === BillingOperationStatusEnum.canceled
        );

      case 'pending':
        return (
          operation.status === BillingOperationStatusEnum.pending ||
          operation.status === BillingOperationStatusEnum.processing
        );
    }
    return false;
  }

  public getUserName(user: User): string {
    if (!user) {
      return '';
    }
    return AccountService.getUserName(user);
  }

  public getTransactions(operation: BillingOperation): BillingTransaction {
    if (!operation.transactions) {
      return new BillingTransaction();
    }
    if (operation.transactions.length > 1) {
      return operation.transactions.find((trans) => {
        if (trans.details) {
          return trans.details.paymentUrl;
        }
      });
    } else {
      return operation.transactions[0];
    }
  }

  public getBalanceName(balance: AccountBalanceTypeEnum): string {
    if (!balance) {
      return '';
    }
    const type = AccountBalanceTypeEnum[balance];
    if (!type) {
      return '';
    }
    return `user-profile.billing.balance_type.${type}`;
  }

  public operationName(operation: BillingOperationEnum): string {
    const type = BillingOperationEnum[operation];
    if (!type) {
      return '';
    }
    return `user-profile.billing.operation.${type}`;
  }

  public statusName(status: BillingOperationStatusEnum): string {
    const type = BillingOperationStatusEnum[status];
    if (!type) {
      return '';
    }
    return `user-profile.billing.status.${type}`;
  }

  public formatUsd(operation: BillingOperation): string {
    return this.formatAmount(operation.amount, operation.currency_id);
  }

  public formatUsdForReceipt(amount: number, currency_id: CurrencyEnum): string {
    return this.formatAmount(amount, currency_id);
  }

  public formatCredits(amount: number): string {
    return this.formatAmount(amount, CurrencyEnum.credits);
  }

  public formatAmount(amount: number, currency: CurrencyEnum): string {
    return amount !== null ? currencies[currency].formatFixed(amount) : '';
  }

  public setActiveOperation(operation: BillingOperation) {
    if (operation.transactions && operation.transactions.length > 0) {
      this.activeOperation = this.activeOperation === operation ? null : operation;
    }
  }

  public receipt(operation: BillingOperation): Partial<CReceipt> {
    return operation?.receipt;
  }

  // Чтобы понять какой знак ставить на сумме (+/-)
  // Возвращает true если происходит списание, false если другие операции
  public debit(operation) {
    // TODO: проверить правильность кодов операций списания
    return [1, 3, 5, 7, 8, 9, 10, 11, 18, 19, 21, 25, 27, 29, 30, 32, 200, 201, 300].includes(operation);
  }

  public openOperationInfo(detail: BillingOperation) {
    this.operationDetailModalOpen = true;
    this.selectedOperation = detail;
  }

  public onCloseCard() {
    this.operationDetailModalOpen = false;
    this.selectedOperation = null;
  }

  /**
   * OLD HISTORY PAGE
   */
}
