<div class="create-payout" [class.mobile]="deviceService?.isMobile()">
  <div class="header">
    <div class="title-wrap">
      <div class="title bold">{{'payout.beneficiary' | translate}}</div>
      <div class="subtitle">{{'payout.enter-tax-info' | translate}}</div>
    </div>
    <div class="steper hidden">
      <app-stepper [steps]="steps"
                   [activeIndex]="activeIndex" [withClick]="true" (stepChanged)="goToStep($event)"
                   (highlightErrors)="onHighlightErrors($event)"></app-stepper>
    </div>
  </div>

  <div class="main">
    <div class="step" *ngIf="activeIndex === stepsEnum.beneficiary">
      <form class="form" [formGroup]="supplierForm" [class.canvalidate]="submittedSupplier" novalidate autocomplete="off">

        <div class="input-section first">
          <!-- Country -->
          <!-- После выбора страны должны актуализироваться дополнительные поля формы! -->
          <div class="input-holder" *ngIf="modeCreate || (supplierDataLoaded && modeEdit)">
            <div class="input-label">
              <div class="label">
                {{'profile-form.about.country' | translate}}
              </div>
            </div>
            <div class="input-field">
              <div class="input">
                <on-ui-form-field-error
                  [error]="isFieldWithError('countryCode') ? 'meetings.form-title-warn' : null">
                <on-ui-select-search formControlName="countryCode"
                              data-test="country-code"
                              [placeholder]="'profile-form.select-country' | translate"
                              [class.disabled]="isLockedField('countryCode')"
                              [options]="countriesOptions">
                </on-ui-select-search>
                </on-ui-form-field-error>
              </div>
            </div>
          </div>
          <div class="loader" *ngIf="showLoader || (!supplierDataLoaded && modeEdit)">
            <spinner2></spinner2>
          </div>

          <!-- Стандартные поля, общие для всех стран -->

          <div *ngIf="canShowFields" class="input-holder" [class.hidden]="isHiddenField('businessType')">
            <div class="input-label">
              <div class="label">
                {{'profile-form.about.type_of_employment' | translate}}
              </div>
            </div>
            <div class="input-field">
              <div class="input">
                <on-ui-form-field-error
                  [error]="isFieldWithError('businessType') ? 'meetings.form-title-warn' : null">
                <on-ui-select formControlName="businessType"
                              data-test="businessType"
                              [placeholder]="'profile-form.select' | translate"
                              [class.disabled]="isLockedField('businessType')"
                              [options]="businessTypeOptions">
                </on-ui-select>
                </on-ui-form-field-error>
              </div>
            </div>
          </div>

        </div>
        <div class="input-section" *ngIf="canShowFields">
          <!-- First name -->
          <div class="input-holder group-inputs">
            <div class="input-label">
              <div class="label">
                {{ isIndividualEmployment ? ('profile-form.about.first_name' | translate) : ('profile-form.about.company_name' | translate)}}
              </div>
            </div>
            <div class="input-field">
              <div class="input">
                <on-ui-form-field-error
                  [error]="isFieldWithError('firstName') ? 'meetings.form-title-warn' : null">
                <on-ui-input [placeholder]="'John'"
                             data-test="firstname"
                             [class.disabled]="isLockedField('firstName')"
                             [error]="isInvalidSupplierForm('firstName') && this.supplierForm?.get('firstName')?.touched"
                             formControlName="firstName">
                </on-ui-input>
                </on-ui-form-field-error>
              </div>
            </div>
          </div>

          <!-- Last name -->
          <div *ngIf="isIndividualEmployment" class="input-holder group-inputs">
            <div class="input-label">
              <div class="label">
                {{'profile-form.about.last_name' | translate}}
              </div>
            </div>
            <div class="input-field">
              <div class="input">
                <on-ui-form-field-error
                  [error]="isFieldWithError('lastName') ? 'meetings.form-title-warn' : null">
                <on-ui-input [placeholder]="'Doe'"
                             data-test="lastname"
                             [class.disabled]="isLockedField('lastName')"
                             [error]="isInvalidSupplierForm('lastName') && this.supplierForm?.get('lastName')?.touched"
                             formControlName="lastName">
                </on-ui-input>
                </on-ui-form-field-error>
              </div>
            </div>
          </div>
        </div>
        <div class="input-section" *ngIf="canShowFields">
          <!-- Email -->
          <div class="input-holder group-inputs">
            <div class="input-label">
              <div class="label">
                {{'auth.email' | translate}}
              </div>
            </div>
            <div class="input-field">
              <div class="input">
                <on-ui-form-field-error
                  [error]="isFieldWithError('email') ? 'meetings.form-title-warn' : null">
                <on-ui-input [placeholder]="'example@site.com'"
                             data-test="email"
                             [class.disabled]="isLockedField('email')"
                             [error]="isInvalidSupplierForm('email') && this.supplierForm?.get('email')?.touched"
                             formControlName="email">
                </on-ui-input>
                </on-ui-form-field-error>
              </div>
            </div>
          </div>

          <!-- Phone -->
          <div class="input-holder">
            <div class="input-label">
              <div class="label">
                {{'profile-form.security.phone' | translate}}
              </div>
            </div>
            <div class="input-field">
              <div class="input">
                <on-ui-form-field-error
                  [error]="isFieldWithError('phone') ? 'meetings.form-title-warn' : null">
                <ngx-intl-phone-input
                  data-test="phone"
                  [cssClass]="'custom'"
                  [searchCountryFlag]="true"
                  [enablePlaceholder]="true"
                  [exclusionarySearch]="true"
                  [localization]="currentLang"
                  [selectFirstCountry]="false"
                  [includeDialCode]="true"
                  [enableAutoCountrySelect]="false"
                  [class.disabled]="isLockedField('phone')" [searchCountryPlaceholder]="'profile-form.select-country' | translate"
                  [selectedCountryISO]="selectedCountry"
                  [separateDialCode]="false"
                  [phoneValidation]="true" [useMask]="true" [showMaskTyped]="true"
                  [inputId]="'country-code-wrap'"
                  [formControlName]="'phone'"
                ></ngx-intl-phone-input>
                </on-ui-form-field-error>
              </div>
            </div>
          </div>
        </div>
        <div class="input-section" *ngIf="canShowFields">
          <!-- Date of birth -->
          <div *ngIf="isIndividualEmployment" class="input-holder">
            <div class="input-label">
              <div class="label">
                {{'profile-form.about.dob' | translate}}
              </div>
            </div>
            <div class="input-field">
              <div class="input">
                <on-ui-form-field-error
                  [error]="isFieldWithError('dob') ? 'meetings.form-title-warn' : null">
                <on-ui-date
                  data-test="dob"
                  formControlName="dob"
                  [class.disabled]="isLockedField('dob')"
                  startView="multi-year" [max]="maxDate"></on-ui-date>
                </on-ui-form-field-error>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="canShowFields" class="subtitle">{{'profile-form.about.enter_bank_details' | translate}}</div>
        <div class="input-section first" *ngIf="supplierFields && canShowFields">
          <div *ngFor="let field of supplierFields; trackBy: trackByFn" class="input-holder group-inputs">
            <div class="input-label">
              <div class="label">
                {{getFieldLabel(field)}}
              </div>
              <div *ngIf="field?.note" class="note">({{field?.note}})</div>
            </div>
            <div class="input-field">
              <div class="input">
                <on-ui-form-field-error
                  [error]="isFieldWithError(field?.key) ? 'meetings.form-title-warn' : null">
                <on-ui-input [placeholder]="field?.placeholder"
                             [attr.data-test]="field.key"
                             [class.disabled]="isLockedField(field.key)"
                             [useMask]="regexToMask(field?.regex)"
                             [error]="isInvalidSupplierForm(field?.key) && this.accountForm?.get(field?.key)?.touched"
                             formControlName="{{field?.key}}">
                </on-ui-input>
                </on-ui-form-field-error>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="step" *ngIf="activeIndex === stepsEnum.payout_method">
      <div *ngIf="!itsCardPayout" class="try-stripe-onboarding">
        <div class="frame-holder">
          <spinner2 *ngIf="!frameLoaded"></spinner2>

          <iframe *ngIf="onboardingFrameUrl" [src]="onboardingFrameUrl" class="{{employmentType}}"
                  allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen frameborder="false" (load)="frameLoad($event)">
          </iframe>
        </div>
      </div>
      <div class="card-payout" *ngIf="itsCardPayout">
        <div *ngIf="!attachPayoutCardPayment && !attachPayoutCardResult">
          <div class="processing">{{'payment-form.in-progress' | translate}}</div>
          <clock-spinner></clock-spinner>
        </div>

        <app-payment-widget-merchant-processing
          *ngIf="attachPayoutCardPayment && !attachPayoutCardResult"
          [payment]="attachPayoutCardPayment" [isAccountPayment]="false" [merchant]="merchant"
          (succeed)="onSuccessAttachCard()"
          (failed)="onFailAttachCard($event)"
        ></app-payment-widget-merchant-processing>

        <ng-template [ngIf]="attachPayoutCardResult === 'success'">
          <ng-container [ngTemplateOutlet]="successRef"></ng-container>
        </ng-template>

        <ng-template [ngIf]="attachPayoutCardResult === 'fail'">
          <ng-container [ngTemplateOutlet]="failedRef"></ng-container>
        </ng-template>
      </div>
    </div>
  </div>

  <div class="actions">
    <div class="additional-text">
      <div class="icon-wrap">
        <app-icon height="36" width="36" file="emoji" icon="sorry"></app-icon>
      </div>
      <div>
        {{'payout.support-text' | translate}}
        <p class="call-chat" (click)="callChat()">{{'payout.support-link' | translate}}</p>
      </div>
    </div>
    <div class="actions-wrapper">
      <on-ui-button [state]="btnState"
                    data-test="continue"
                    class="primary" [class.disabled]="btnState === 'progress'"
                    label="{{isFinalStep ? 'profile-form.finish' : 'collections-constructor.incomplete-alert.continue'}}"
                    (onclick)="isFinalStep ? finishForm() : sendSupplier()">
      </on-ui-button>
    </div>

  </div>
</div>


<app-alert *ngIf="error" type="warning" [btnPosition]="'center'">
  <ng-container alert-title>{{'not-found.title' | translate}}</ng-container>
  <ng-container alert-body>
    {{error?.error?.message}}
  </ng-container>
  <ng-container alert-buttons>
    <on-ui-button label="user-profile.service-card.alert.close"
                  state="default"
                  class="primary outline center"
                  (onclick)="closeAlert()">
    </on-ui-button>
  </ng-container>
</app-alert>

<ng-template #successRef>
  <div class="finish-success">
    <div class="title">{{ 'meetings.wizard.add-card-success' | translate }}</div>
    <svg class="icon" width="98" height="98" viewBox="0 0 98 98" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M49 0C21.9803 0 0 21.9803 0 49C0 76.0197 21.9803 98 49 98C76.0197 98 98 76.0197 98 49C98 21.9803 76.0197 0 49 0Z" fill="#87D37C"/>
      <path d="M74.7524 38.2306L47.0937 65.7585C46.2639 66.5843 45.1747 67 44.0854 67C42.9962 67 41.9069 66.5843 41.0772 65.7585L27.2482 51.9945C25.5839 50.3389 25.5839 47.6619 27.2482 46.0063C28.9116 44.3498 31.6005 44.3498 33.2647 46.0063L44.0854 56.7761L68.7359 32.2423C70.3993 30.5859 73.0882 30.5859 74.7524 32.2423C76.4159 33.898 76.4159 36.5741 74.7524 38.2306Z" fill="#FAFAFA"/>
    </svg>
  </div>
</ng-template>

<ng-template #failedRef>
  <div class="finish-fail">
    <div class="title">{{ 'meetings.wizard.pay-fail' | translate }}</div>
    <svg class="icon" width="98" height="98" viewBox="0 0 98 98" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M49 0C21.9803 0 0 21.9803 0 49C0 76.0197 21.9803 98 49 98C76.0197 98 98 76.0197 98 49C98 21.9803 76.0197 0 49 0Z" fill="#E86F79"/>
      <rect x="29" y="35" width="8" height="50" rx="4" transform="rotate(-45 29 35)" fill="white"/>
      <rect x="35" y="70" width="8" height="50" rx="4" transform="rotate(-135 35 70)" fill="white"/>
    </svg>
    <div class="description">{{errorDescription}}</div>
  </div>
</ng-template>
