<div class="transaction-filter">
  <div class="transaction-filter__btn" (click)="popup_open = true">
    <span>{{'transaction-history.for-all' | translate}}</span>
    <app-icon class="transaction-filter__btn-arrow-ico" width="11" height="6" icon="angle-arrow"></app-icon>
  </div>

  <div class="transaction-filter-popup" *ngIf="popup_open">
    <div class="shade" (click)="popupState(false)"></div>
    <div class="window" [ngClass]="position">
      <p class="text">{{'transaction-history.for-all' | translate}}</p>
    </div>
  </div>
</div>
