import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PaymentWizardDialogComponent } from '@app/account/finance-page/components/payment-wizard-dialog/payment-wizard-dialog.component';
import { MobileModalModule } from '@app/core/components/mobile-modal/mobile-modal.module';
import { ModalModule } from '@app/core/components/modal/modal.module';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from '@shared/icon/icon.component';
import {
  FinancePlanActivateFormModule,
  FinancePlanActivateFormV2Module,
  FinancePlanBuyCreditsFormModule,
  FinancePlanBuyCreditsFormV2Module,
} from 'lingo2-forms';
import { DateFnsModule } from 'lingo2-ngx-date-fns';
import { OnUiButtonModule } from 'onclass-ui';
import { CurrentPlanCardComponent } from './current-plan-card.component';

@NgModule({
  declarations: [CurrentPlanCardComponent],
  exports: [CurrentPlanCardComponent],
  imports: [
    CommonModule,
    OnUiButtonModule,
    TranslateModule,
    DateFnsModule,
    ModalModule,
    MobileModalModule,
    FinancePlanBuyCreditsFormModule,
    FinancePlanBuyCreditsFormV2Module,
    FinancePlanActivateFormModule,
    FinancePlanActivateFormV2Module,
    PaymentWizardDialogComponent,
    IconComponent,
  ],
})
export class CurrentPlanCardModule {}
