<div class="payment" [class.test]="isTestBeneficiary">
  <div class="label">{{supplier.countryCode}}</div>
  <div *ngIf="isTestBeneficiary" class="test-beneficiary-label">
    {{'profile-steps.test-beneficiary' | translate}}
  </div>
  <div class="info">
    <div class="main-info">
      <div class="icon">
        <app-icon width="37" height="27" [icon]="typeCard ? 'badge-bank_card' : 'badge-bank'"></app-icon>
      </div>
      <div class="balance">
        <ng-template [ngIf]="autoPayout"
                     [ngIfThen]="autoBalances"
                     [ngIfElse]="manualBalances">
        </ng-template>
      </div>
      <div class="menu">
        <on-ui-card-menu>
          <a class="menu-item" *ngIf="!isDefault"
             data-test="set-default"
             (click)="setDefault()">{{'payout.set-default' | translate}}</a>
          <a class="menu-item"
             data-test="edit-card"
             (click)="editMethod()">{{'payout.edit' | translate}}</a>
          <a class="menu-item danger"
             data-test="delete-card"
             (click)="deleteMethod()">{{'payout.remove' | translate}}</a>
        </on-ui-card-menu>
      </div>
    </div>
    <div class="additional-info">
      <ng-template [ngIf]="typeBank"
                   [ngIfThen]="bankAdditional"
                   [ngIfElse]="cardAdditional">
      </ng-template>
    </div>
    <div class="status-info">
      <div class="status {{status}}">{{'payout.status.' + status | translate}}</div>
      <div class="info-icon" *ngIf="showPopover">
        <span [appPopover]="statusPopover" position="bottom" class="more-info">
          <app-icon width="16" height="16" icon="ready-slide"></app-icon>
        </span>
      </div>
    </div>
  </div>
  <div class="footer">
    <div class="history">
      <a *ngIf="!isDefault" (click)="setDefault()"
         data-test="set-default"
         class="link">{{'payout.set-default' | translate}}</a>
      <span *ngIf="isDefault"
            data-test="is-default"
            class="default">{{'payout.default' | translate}}</span>
    </div>
    <div class="action" *ngIf="isDefault">
      <on-ui-button
        *ngIf="manualPayout"
        (onclick)="onGetMoney()"
        data-test="get-money"
        class="secondary"
        [label]="'payout.get-money'"></on-ui-button>

      <on-ui-button
        *ngIf="autoPayout"
        (onclick)="openPayoutAlert()"
        class="secondary"
        data-test="auto-payout"
        [label]="'payout.autopayout'"></on-ui-button>
    </div>
  </div>
</div>

<ng-template #bankAdditional>
  <div class="add-info-text" >
    {{'payout.bank' | translate}}: <span class="value">{{bankName}}</span></div>
  <div class="add-info-text" >{{'payout.bank-account' | translate}}: <span class="value">{{bankAccount}}</span></div>
  <div class="add-info-text" >{{'payout.swift' | translate}}: <span class="value">{{bankInfo}}</span></div>
</ng-template>
<ng-template #cardAdditional>
  <div class="add-info-text" >{{'payout.card-number' | translate}}: <span class="value">{{cardNumber}}</span></div>
  <div class="auto-payout-text">
    {{'payout.auto-payout-help' | translate}}
  </div>
</ng-template>

<ng-template #manualBalances>
  <div class="balance-info">
    <div class="full-balance bold">
      {{'payout.balance' | translate}}: <span class="amount">{{formatBalance(supplier.balance)}}</span>
    </div>
    <div class="wait-balance" *ngIf="supplier?.currencyCode === supplier?.payoutAccount?.currencyCode">
      {{'payout.upcoming' | translate}}: <span class="amount">{{formatBalance(supplier.upcomingBalance)}}</span>
      <span [appPopover]="waitBalancePopover" position="bottom" class="more-info">
        <app-icon width="16" height="16" icon="ready-slide"></app-icon>
      </span>
    </div>
    <div class="wait-balance" *ngIf="supplier?.currencyCode !== supplier?.payoutAccount?.currencyCode">
      ≈ <span class="amount">{{formatBalance(supplier?.balanceLocal, supplier?.payoutAccount?.currencyCode)}}</span>
      <span [appPopover]="rateBalancePopover" position="bottom" class="more-info">
        <app-icon width="16" height="16" icon="ready-slide"></app-icon>
      </span>
    </div>


    <div class="wait-balance">
      {{'payout.advances' | translate}}: <span class="amount">{{formatBalance(supplier.advancesBalance)}}</span>
    </div>
  </div>
</ng-template>

<ng-template #autoBalances>
  <div class="balance-info">
    <div class="full-balance bold">
      {{'payout.upcoming' | translate}}: <span class="amount">{{formatBalance(supplier.upcomingBalance)}}</span>
      <span [appPopover]="autoPayoutPopover" position="bottom" class="more-info">
        <app-icon width="16" height="16" icon="ready-slide"></app-icon>
      </span>
    </div>
  </div>
</ng-template>



<ng-template #autoPayoutPopover>
  <div style="line-height: normal; width: 300px;">
    {{'payout.auto-balance-text' | translate}}
  </div>
</ng-template>

<ng-template #waitBalancePopover>
  <div style="line-height: normal; width: 300px;">
    {{'payout.wait-balance-text' | translate}}
  </div>
</ng-template>

<ng-template #rateBalancePopover>
  <div style="line-height: normal; width: 300px;">
    {{'payout.rate-balance-text' | translate}}
  </div>
</ng-template>

<ng-template #statusPopover>
  <div style="line-height: normal; width: 300px;">
    {{'payout.text.' + status | translate}}
  </div>
</ng-template>

<app-alert *ngIf="showAutoPayoutAlert" type="info" [btnPosition]="'center'">
  <ng-container alert-icon>
    <app-icon width="36" height="36" file="emoji" icon="shy"></app-icon>
  </ng-container>
  <ng-container alert-title>
    {{'payout.auto-payout-alert-title' | translate}}
    </ng-container>
  <ng-container alert-body>
    {{'payout.auto-payout-alert-text' | translate}}
  </ng-container>
  <ng-container alert-buttons>
    <on-ui-button [label]="'payout.auto-payout-alert-btn'"
                  class="primary outline center"
                  (onclick)="closePayoutAlert()">
    </on-ui-button>
  </ng-container>
</app-alert>

<app-alert *ngIf="showDeleteSupplierAlert" type="info" [btnPosition]="'center'">
  <ng-container alert-icon>
    <app-icon width="36" height="36" file="emoji" icon="shy"></app-icon>
  </ng-container>
  <ng-container alert-title>
    {{'payout.delete-supplier-title' | translate}}
  </ng-container>
  <ng-container alert-body>
    {{'payout.delete-supplier-text' | translate}}
  </ng-container>
  <ng-container alert-buttons>
    <on-ui-button [label]="'payout.delete-supplier-btn'"
                  class="primary outline center"
                  (onclick)="closDeleteSupplierAlert()">
    </on-ui-button>
  </ng-container>
</app-alert>
