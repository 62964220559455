<app-user-service-card-2
  *ngIf="showCard"
  [service]="user_service_contract?.user_service"
  [displayMode]="displayMode"
  (openUserServiceInModal)="onToggleUserServiceInModal($event)">
  <div class="service-card-menu">
    <on-ui-card-menu>
      <a class="menu-item warn" *ngIf="user_service_contract?.purl" [href]="user_service_contract?.purl">[⚠️] Panel [contract]</a>
      <a class="menu-item warn" *ngIf="user_service_contract?.user_service?.purl" [href]="user_service_contract?.user_service?.purl">[⚠️] Panel [service]</a>
      <div class="menu-item warn" *ngIf="debug$ | async" (click)="printDebugInfo()">DEBUG</div>
      <div class="menu-item danger" *ngIf="user_service_contract?.can?.break" (click)="showBreakAlert()">
        {{!user_service_contract.is.ongoing
        ? ('classrooms.classroom-page.decline-participant' | translate)
        : ('classrooms.classroom-page.cancel-subscription' | translate)}}
      </div>
    </on-ui-card-menu>
  </div>

  <div class="service-card-actions">
    <on-ui-button
      *ngIf="user_service_contract?.can?.break"
      class="danger outline break"
      (onclick)="showBreakAlert()"
      label="library.user-service-card.cancel-subscription">
    </on-ui-button>

    <ng-template [ngIf]="user_service_contract?.can?.setup_payments && user_service_contract?.visit_info?.unpaid_order">
      <on-ui-button
        *ngIf="user_service_contract?.is?.accepting"
        class="secondary signup-button"
        (onclick)="openPaymentForm()"
        label="dashboard.label-btn.accept-and-pay">
      </on-ui-button>

      <on-ui-button
        *ngIf="user_service_contract?.is?.suspended"
        class="secondary renew-button"
        (onclick)="openPaymentForm()"
        label="library.user-service-card.renew_subscription">
      </on-ui-button>
    </ng-template>
  </div>
</app-user-service-card-2>

<app-alert *ngIf="breakAlertVisible && alertData" type="warning" [btnPosition]="'center'">
  <ng-container alert-icon>
    <app-icon width="34" height="36" file="emoji" icon="think"></app-icon>
  </ng-container>
  <ng-container alert-title>{{ alertData.title | translate }}</ng-container>
  <ng-container alert-body>{{ alertData.description | translate }}</ng-container>
  <ng-container alert-buttons>
    <on-ui-button label="{{ alertData.closeBtnText }}"
                  state="default"
                  class="transparent"
                  (onclick)="closeBreakAlert()">

    </on-ui-button>
    <on-ui-button label="{{alertData.confirmBtnText}}"
                  state="default"
                  class="primary center"
                  (onclick)="breakUserServiceContract()">
    </on-ui-button>
  </ng-container>
</app-alert>

<app-user-service-contract-checkout-dialog
  *ngIf="paymentFormVisible"
  [userServiceContractId]="user_service_contract.id"
  (succeed)="onPaymentSucceed()"
  (closed)="closePaymentForm()"
></app-user-service-contract-checkout-dialog>


<app-modal *ngIf="deviceService.isDesktop() && userServiceCardFullInModal"
           [canClose]="false"
           (closed)="onToggleUserServiceInModal()">
  <app-modal-body>
    <app-user-service-card-full [userService]="openedUserService"
                                [modal]="true"
                                (inViewportChange)="onIinViewportChange($event)"
                                (isCloseAllModals)="onToggleUserServiceInModal()">
    </app-user-service-card-full>
  </app-modal-body>
  <app-modal-ad>
    <ng-template [ngIf]="!surfaceBlockDisplay">
      <app-user-service-surface-block [userService]="openedUserService"></app-user-service-surface-block>
    </ng-template>
  </app-modal-ad>
</app-modal>

<app-mobile-modal *ngIf="!deviceService.isDesktop() && userServiceCardFullInModal"
                  [titleVisible]="true"
                  (closed)="onToggleUserServiceInModal()">
  <app-mobile-modal-body>
    <app-user-service-card-full [userService]="openedUserService" [modal]="true"></app-user-service-card-full>
  </app-mobile-modal-body>
</app-mobile-modal>
