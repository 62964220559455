<div class="withdraw" [class.mobile]="deviceService.isMobile()">
  <div class="header">
    <div class="info-wrap">
      <div class="logo">
        <app-icon class="lock-icon" icon="payout"></app-icon>
      </div>
      <div class="info">
        <div class="title bold">{{'finance.tabs.payout' | translate}}</div>
      </div>
    </div>
    <div class="steper">
      <app-stepper [steps]="steps"
                   [activeIndex]="activeIndex"></app-stepper>
    </div>
  </div>
  <div class="line"></div>

  <div class="main" *ngIf="screen === 'form'">
    <div class="step" *ngIf="activeIndex === stepsEnum.amount">
      <div class="step-amount">
        <div class="withdraw-info">
          <div class="title">{{'withdraw.available-for-payout' | translate}}</div>
          <div class="text">{{'withdraw.minimum-amount' | translate}}: {{formatPrice(minimalAmount)}}</div>
        </div>
        <form class="form" [formGroup]="amountForm" novalidate autocomplete="off">
          <div class="input-holder vertical">
            <div class="label">
              <div class="text">{{'dashboard.wallet.balance' | translate}}:</div>
              <div class="value">{{formatPrice(balanceAmount)}}</div>
            </div>
            <div class="input">
              <on-ui-input [placeholder]="'transaction-history.amount' | translate"
                           data-test="amount"
                           [class.error]="amountError" [class.success]="amountSuccess" [useMask]="'0*'"
                           formControlName="amount">
              </on-ui-input>
              <div *ngIf="amountError || amountSuccess"
                   class="input-description"
                   [class.error]="amountError" [class.success]="amountSuccess">
                {{amountError ? amountError : amountSuccess ? amountSuccess : ''}}
              </div>
            </div>
          </div>
        </form>
      </div>

    </div>


    <div class="step" *ngIf="activeIndex === stepsEnum.account_selection">
      <div class="step-account">
        <div class="withdraw-info">
          <div class="title">{{'withdraw.where-withdrawal' | translate}}</div>
        </div>

        <div class="withdraw-list">
          <div class="account selected">
            <div class="number">
              {{withdrawAccountLabel}}
            </div>
            <div class="logo">
              <app-icon *ngIf="isCard" class="lock-icon" width="32" height="28" icon="badge-bank_card"></app-icon>
              <app-icon *ngIf="isBank" class="lock-icon" width="32" height="32" icon="badge-bank"></app-icon>
            </div>
          </div>
        </div>
      </div>

      <div class="step-order">
        <div class="order">
          <div class="info">
            <div class="row">
              <div class="name">{{'transaction-history.amount' | translate}}:</div>
              <div class="value">{{formatPrice(withdrawAmount)}}</div>
            </div>
            <div class="row">
              <div class="name">{{'withdraw.processing-fee' | translate}}:</div>
              <div class="value">??? USD</div>
            </div>
          </div>
          <div class="total">
            <div class="row">
              <div class="name">{{'withdraw.will-be-credited' | translate}}:</div>
              <div class="value bold">{{formatPrice(calcTotalAmount)}}</div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
  <div class="main" *ngIf="screen !== 'form'">
    <ng-template [ngIf]="screen === 'success'">
      <ng-container [ngTemplateOutlet]="successRef"></ng-container>
    </ng-template>

    <ng-template [ngIf]="screen === 'fail'">
      <ng-container [ngTemplateOutlet]="failedRef"></ng-container>
    </ng-template>
  </div>

  <div class="line"></div>
  <div class="actions">
    <div class="additional-text">
      <div class="logo">
        <app-icon *ngIf="isCard" class="lock-icon" width="50" height="46" icon="badge-bank_card"></app-icon>
        <app-icon *ngIf="isBank" class="lock-icon" width="50" height="50" icon="badge-bank"></app-icon>
      </div>
      <div class="data">
        <div class="text">{{'withdraw.will-be-credited-to' | translate}}:</div>
        <div class="bank">{{isBank ? bankAccount?.brand : cardAccount?.routingNumber}}</div>
        <div class="account">{{withdrawAccountLabel}}</div>
      </div>
    </div>
    <div class="actions-wrapper">
      <on-ui-button [state]="btnState"
                    [class]="isFinal ? 'primary' : 'secondary'"
                    [class.disabled]="btnState === 'progress' || !amountFormValid"
                    [label]="isFinal ? ('withdraw.get' | translate: {'amount': formatPrice(calcTotalAmount)})
                    : isLast ? ('user-service-checkout-wizard.close' | translate)
                    : ('meetings.wizard.next' | translate)"
                    (click)="isLast ? closeForm() : nextStep()">
      </on-ui-button>
    </div>

  </div>
</div>


<ng-template #successRef>
  <div class="finish-success">
    <div class="title">{{ 'withdraw.success-text' | translate }}</div>
    <svg class="icon" width="98" height="98" viewBox="0 0 98 98" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M49 0C21.9803 0 0 21.9803 0 49C0 76.0197 21.9803 98 49 98C76.0197 98 98 76.0197 98 49C98 21.9803 76.0197 0 49 0Z" fill="#87D37C"/>
      <path d="M74.7524 38.2306L47.0937 65.7585C46.2639 66.5843 45.1747 67 44.0854 67C42.9962 67 41.9069 66.5843 41.0772 65.7585L27.2482 51.9945C25.5839 50.3389 25.5839 47.6619 27.2482 46.0063C28.9116 44.3498 31.6005 44.3498 33.2647 46.0063L44.0854 56.7761L68.7359 32.2423C70.3993 30.5859 73.0882 30.5859 74.7524 32.2423C76.4159 33.898 76.4159 36.5741 74.7524 38.2306Z" fill="#FAFAFA"/>
    </svg>
  </div>
</ng-template>

<ng-template #failedRef>
  <div class="finish-fail">
    <div class="title">{{ 'withdraw.fail-text' | translate }}</div>
    <svg class="icon" width="98" height="98" viewBox="0 0 98 98" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M49 0C21.9803 0 0 21.9803 0 49C0 76.0197 21.9803 98 49 98C76.0197 98 98 76.0197 98 49C98 21.9803 76.0197 0 49 0Z" fill="#E86F79"/>
      <rect x="29" y="35" width="8" height="50" rx="4" transform="rotate(-45 29 35)" fill="white"/>
      <rect x="35" y="70" width="8" height="50" rx="4" transform="rotate(-135 35 70)" fill="white"/>
    </svg>
    <div class="description">{{errorDescription}}</div>
  </div>
</ng-template>
