import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AlertModule } from '@core/components/alert/alert.module';
import { ModalModule } from '@core/components/modal/modal.module';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from '@shared/icon/icon.component';
import { OnUiButtonModule, OnUiCardMenuModule, OnUiPopoverModule } from 'onclass-ui';
import { PayoutCardComponent } from './payout-card.component';

@NgModule({
  declarations: [PayoutCardComponent],
  imports: [
    CommonModule,
    TranslateModule,
    ModalModule,
    OnUiButtonModule,
    OnUiCardMenuModule,
    OnUiPopoverModule,
    AlertModule,
    IconComponent,
  ],
  exports: [PayoutCardComponent],
})
export class PayoutCardModule {}
