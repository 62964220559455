<section *ngIf="plan" class="current-plan-card" [class.mobile]="deviceService.isMobile()">
  <div class="meta">
    <div class="left">
      <app-icon width="61" height="61" [ngStyle]="{ 'fill' : plan?.plan?.details?.color }" icon="finance-plan"></app-icon>

      <ng-template [ngIf]="deviceService.isMobile()">
        <div>
          <h3 class="title">{{ plan?.plan?.details?.title | translate }}</h3>
          <ng-container *ngTemplateOutlet="updatePlan"></ng-container>
        </div>
      </ng-template>
    </div>
    <div class="center">
      <ng-template [ngIf]="!deviceService.isMobile()">
        <h3 class="title">{{ plan?.plan?.details?.title | translate }}</h3>
      </ng-template>

      <ng-container *ngIf="!deviceService.isMobile()">
        <ng-container *ngTemplateOutlet="updatePlan"></ng-container>
      </ng-container>

      <div class="properties">
        <div *ngFor="let term of plan?.plan?.details?.tariff_terms; trackBy: trackByFn" class="properties-item">
          <div class="label-wrap">
            <div class="icon-wrap">
              <app-icon width="12" height="9" file="finance-plan" icon="property"></app-icon>
            </div>
            <span class="label">{{ clearPercents(clearVariables(term?.label | translate)) }}</span>
          </div>
          <span class="value">{{ clearText(term?.label | translate) }} {{ percent(term?.label | translate) }}</span>
        </div>
      </div>
    </div>
    <div class="right">
      <div class="top-info">
        <span
          class="credit-value">{{ 'subscription-plans.credit-value-label' | translate }} {{ remainingCredits }}</span>
        <span *ngIf="!notActiveSubscription" class="pay-action" (click)="openBuyCreditsModal()">
          {{ 'subscription-plans.pay-action' | translate }}
        </span>
      </div>
      <div class="bottom-info">
        <ng-template [ngIf]="!notActiveSubscription">
          <on-ui-button [class]="isMaxPlan ? 'primary outline' : 'secondary'"
                        [label]="isMaxPlan ? 'subscription-plans.change-plan' : 'subscription-plans.improve-plan'"
                        (onclick)="upgradePlan()"></on-ui-button>
        </ng-template>

        <ng-template [ngIf]="notActiveSubscription">
          <on-ui-button [class]="'transparent'"
                        [label]="isMaxPlan ? 'subscription-plans.change-plan' : 'subscription-plans.improve-plan'"
                        (onclick)="upgradePlan()"></on-ui-button>
          <on-ui-button [class]="'secondary'"
                        [label]="'subscription-plans.resume'"
                        (onclick)="toggleResumePlan()"></on-ui-button>
        </ng-template>
      </div>
    </div>
  </div>

  <ng-template [ngIf]="!isMeVersion()">
  <div *ngIf="plan?.next_plan_id && plan.plan?.order > nextPlan?.order" class="next-plan">
    <div class="next-plan-left">
      <div class="icon-wrap">
        <app-icon width="20" height="20" icon="clock3" class="clock-icon"></app-icon>
      </div>

      <div class="plan-text-wrap">
        <span class="next-plan-text" [innerHTML]="'subscription-plans.next-plan-text' | translate: {
          'plan': ('<span class=&quot;bold&quot;>' + (nextPlan?.details?.title | translate) + '</span>'),
          'date': (expires_at | dfnsFormat: 'dd.MM.yyyy'),
          'price': getFormattedPrice()
        }"></span>&nbsp;
      </div>
    </div>

    <span class="next-price" *ngIf="!isFreePlan">{{ getFormattedPrice() }}</span>
  </div>
  </ng-template>

  <ng-template [ngIf]="isMeVersion()">
    <div *ngIf="!nextPlan" class="next-plan">
      <div class="next-plan-left">
        <div class="icon-wrap">
          <app-icon width="20" height="20" icon="clock3" class="clock-icon"></app-icon>
        </div>

        <div class="plan-text-wrap">
        <span class="next-plan-text" [innerHTML]="'subscription-plans.next-plan-text-onclass-me' | translate: {
          'date': (expires_at | dfnsFormat: 'dd.MM.yyyy'),
        }"></span>
        </div>
      </div>
    </div>
  </ng-template>

  <div *ngIf="notActiveSubscription" class="not-active-subscription-plan">
      <div class="icon-wrap">
        <app-icon width="25" height="25" icon="billing-failed"></app-icon>
      </div>

      <div class="plan-text-wrap">
        <span class="next-plan-text">{{'subscription-plans.plan-inactive' | translate}} {{'subscription-plans.reason-for-deactivation.' + reasonForDeactivation | translate}}</span>&nbsp;
      </div>
  </div>
</section>

<section *ngIf="!plan" class="current-plan-card no-plan">
  <div class="meta">
    <div class="left">
      <app-icon width="61" height="61" file="finance-plan" icon="finance-plan-none"></app-icon>
      <h3 class="title">{{ 'subscription-plans.plan-not-selected' | translate }}</h3>
    </div>
    <div class="pull-right">
        <on-ui-button class="primary"
                      [label]="'subscription-plans.choose-plan'"
                      (onclick)="upgradePlan()">
        </on-ui-button>
      </div>
    </div>
</section>

<ng-template #updatePlan>
  <div class="update">
    <span class="update-label">{{ 'subscription-plans.next-update-label' | translate }}</span>
    <span class="update-value" *ngIf="!plan?.next_plan_id || !plan?.plan?.is_default">
          {{ expires_at | dfnsFormat: 'dd.MM.yyyy' }}
        </span>
    <span class="update-value" *ngIf="plan?.plan?.is_default && plan?.next_plan_id">
      {{ expires_at_for_free_plan | dfnsFormat: 'dd.MM.yyyy' }}
    </span>

    <br/>

    <ng-template [ngIf]="!isMeVersion()">
      <span class="cancel-action" *ngIf="!plan?.plan?.is_default && plan.plan?.order <= nextPlan?.order" (click)="openCancelPlanModal()">
        {{ 'subscription-plans.cancel-subscription' | translate }}
      </span>
    </ng-template>

    <ng-template [ngIf]="isMeVersion()">
      <span class="cancel-action" *ngIf="nextPlan" (click)="openCancelPlanModal()">
        {{ 'subscription-plans.cancel-subscription' | translate }}
      </span>
    </ng-template>
  </div>
</ng-template>

<ng-template [ngIf]="buyCreditsModalOpened">
  <app-finance-plan-buy-credits-form-v2
    [plan]="plan?.plan"
    (activatePlan)="getPlanForCredits()"
    (success)="closeBuyCreditsModal()"
    (close)="closeBuyCreditsModal()">
  </app-finance-plan-buy-credits-form-v2>
</ng-template>

<ng-template [ngIf]="cancelPlanModalOpened">
  <app-finance-plan-activate-form-v2
    [plan]="plan?.plan"
    [displayMode]="'cancel'"
    [tenant]="tenant"
    (closed)="closeCancelPlanModal()"
    (finished)="onCancelPlanFinished()">
  </app-finance-plan-activate-form-v2>
</ng-template>

<ng-template [ngIf]="openResumePlanModal">
  <app-payment-wizard-dialog [plan]="plan" (closed)="toggleResumePlan()"></app-payment-wizard-dialog>
</ng-template>
