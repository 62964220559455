import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ChangableComponent } from '@models/changable.component';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from '@shared/icon/icon.component';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-transactions-filter',
  standalone: true,
  imports: [CommonModule, TranslateModule, IconComponent],
  templateUrl: './transactions-filter.component.html',
  styleUrls: ['./transactions-filter.component.scss'],
})
export class TransactionsFilterComponent extends ChangableComponent implements OnInit {
  @Input() position = 'popup-left';
  @Input() id: string;
  @Output() onChange = new EventEmitter<void>();

  public popup_open = false;

  constructor(public deviceService: DeviceDetectorService, protected readonly cdr: ChangeDetectorRef) {
    super(cdr);
  }

  ngOnInit(): void {
    this.detectChanges();
  }

  public popupState(arg) {
    this.popup_open = arg;
  }
}
