import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { IStep } from '@app/content-editor/content-form-page/_components/stepper';
import { BillingService, ContextService, PlatformService } from '@core/services';
import { BillingV2Service } from '@core/services/lingo2-account/billing-v2.service';
import { BillingApiPayoutDto, BillingApiSupplierResponseDto } from '@lingo2-billing-sdk/models';
import { ChangableComponent } from '@models/changable.component';
import { Store } from '@ngrx/store';
import { ErrorNotificationService } from '@shared/error-notification/error-notification.service';
import { loadMe } from '@store/actions/profile.actions';
import { CCurrencyAmount, User, UserRoleEnum } from 'lingo2-models';
import { CurrencyEnum } from 'lingo2-models/dist/account/enums';
import { DeviceDetectorService } from 'ngx-device-detector';
import { OnUiButtonState } from 'onclass-ui';
import { debounceTime, takeUntil } from 'rxjs/operators';

export enum StepsEnum {
  amount = 1,
  account_selection = 2,
}

export const STEPS: IStep[] = [
  {
    id: StepsEnum.amount,
    label: 'withdraw.amount',
    route: null,
    touched: false,
    invalid: null,
  },
  {
    id: StepsEnum.account_selection,
    label: 'withdraw.account-selection',
    route: null,
    touched: false,
    invalid: null,
  },
];

@Component({
  selector: 'app-withdraw-money-form',
  templateUrl: './withdraw-money-form.component.html',
  styleUrls: ['./withdraw-money-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WithdrawMoneyFormComponent extends ChangableComponent implements OnInit {
  @Input() set supplier(supplier: BillingApiSupplierResponseDto) {
    this._supplier = supplier;
  }

  public get supplier() {
    return this._supplier;
  }

  @Output() success = new EventEmitter<boolean>();
  @Output() fail = new EventEmitter<boolean>();
  @Output() close = new EventEmitter<boolean>();

  public steps: IStep[] = STEPS;
  public activeIndex = StepsEnum.amount;
  public stepsEnum = StepsEnum;
  public amountForm: UntypedFormGroup;
  public minimalAmount = new CCurrencyAmount();
  public balanceAmount = new CCurrencyAmount();
  public btnState: OnUiButtonState = 'default';
  public error: any;
  public amountError: any;
  public amountSuccess: any;
  private _supplier: BillingApiSupplierResponseDto;

  private dataToSend = new BillingApiPayoutDto();
  public withdrawAmount = new CCurrencyAmount();

  public errorDescription: string;
  public me: User;

  public screen: 'form' | 'success' | 'fail' = 'form';
  public isFinal = false;
  public isLast = false;

  constructor(
    public errorNotificationService: ErrorNotificationService,
    protected readonly cdr: ChangeDetectorRef,
    protected readonly platform: PlatformService,
    protected billingService: BillingService,
    protected contextService: ContextService,
    protected billingV2Service: BillingV2Service,
    protected store: Store,
    protected fb: UntypedFormBuilder,
    public deviceService: DeviceDetectorService,
  ) {
    super(cdr, platform);
  }

  ngOnInit(): void {
    this.contextService.me$.pipe(takeUntil(this.destroyed$)).subscribe({
      next: (me) => {
        this.me = me;
        this.calcAmounts();
      },
      error: (error) => {
        this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
        this.store.dispatch(loadMe());
      },
    });

    this.amountForm.valueChanges
      .pipe(debounceTime(300))
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: () => {
          this.amountError = null;
          this.amountSuccess = null;
          const inputAmount = this.amountForm.get('amount').value;
          if (inputAmount) {
            if (inputAmount < this.minimalAmount.amount) {
              this.amountError = 'Minimum withdrawal amount: ' + this.formatPrice(this.minimalAmount);
            } else if (inputAmount > this.balanceAmount.amount) {
              this.amountError = 'Insufficient funds';
            } else {
              this.amountSuccess = 'Correct amount';
            }
          } else {
            this.amountError = 'The field is required';
          }
          this.detectChanges();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'OTHER-PROBLEM');
          this.amountSuccess = null;
          this.amountError = 'This form is broken';
          this.detectChanges();
        },
      });
  }

  public calcAmounts() {
    this.minimalAmount.amount = this.isDeveloper ? 1 : Number(this.supplier.minimalPayout);
    this.minimalAmount.currency_id = CurrencyEnum[this.supplier.currencyCode.toLowerCase()];

    this.balanceAmount.amount = Number(this.supplier.balance);
    this.balanceAmount.currency_id = CurrencyEnum[this.supplier.currencyCode.toLowerCase()];

    this.amountForm = this.fb.group({
      amount: ['', [CustomValidator.numeric]],
    });
  }

  public get isDeveloper() {
    return this.me.roles.includes(UserRoleEnum.developer) || this.me.roles.includes(UserRoleEnum.tester);
  }

  public closeAlert() {
    this.error = null;
  }

  public formatPrice(amount: CCurrencyAmount) {
    return amount ? this.billingService.formatAmountFull(amount.amount, amount.currency_id) : null;
  }

  public isInvalidAmountForm(formControlName: string): boolean {
    return this.amountForm.get(formControlName)?.invalid;
  }

  public get isBank() {
    return !!this.supplier?.payoutAccount?.bankAccount;
  }

  public get bankAccount() {
    return this.supplier?.payoutAccount?.bankAccount;
  }

  public get isCard() {
    return !!this.supplier?.payoutAccount?.cardAccount;
  }

  public get cardAccount() {
    return this.supplier?.payoutAccount?.bankAccount;
  }

  public get withdrawAccountLabel() {
    if (this.isBank) {
      return '*******************' + this.bankAccount?.label;
    } else {
      return '**** **** **** ' + this.cardAccount?.label;
    }
  }

  public get amountFormValid() {
    return this.amountForm.valid && !this.amountError && !!this.amountSuccess;
  }

  private saveAmount() {
    this.dataToSend.amount = this.amountForm.getRawValue()['amount'];
    this.withdrawAmount.amount = this.amountForm.getRawValue()['amount'];
    this.withdrawAmount.currency_id = CurrencyEnum[this.supplier.currencyCode.toLowerCase()];
  }

  private saveAccount() {
    this.dataToSend.supplierId = this.supplier.id;
    this.billingV2Service
      .payout(this.dataToSend)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (res) => {
          this.onSuccess();
          this.detectChanges();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'PAYMENT-PROBLEM');
          this.errorDescription = JSON.stringify(error.error.message);
          this.onError();
        },
      });
  }

  public onSuccess() {
    this.screen = 'success';
    this.isLast = true;
    this.isFinal = false;
    this.success.emit(true);
  }

  public onError() {
    this.screen = 'fail';
    this.isLast = true;
    this.isFinal = false;
    this.fail.emit(true);
  }

  public nextStep() {
    if (this.activeIndex === this.stepsEnum.amount) {
      this.saveAmount();
      this.activeIndex = this.stepsEnum.account_selection;
      this.isFinal = true;
    } else if (this.activeIndex === this.stepsEnum.account_selection) {
      this.saveAccount();
    }
  }

  public get calcTotalAmount() {
    // если есть FEE то вычесть от выбранной суммы
    // иначе просто вывести указанную сумму
    return this.withdrawAmount;
  }

  public closeForm() {
    this.close.emit(true);
  }
}

export class CustomValidator {
  // Number only validation
  static numeric(control: AbstractControl) {
    const val = control.value;

    if (val === null || val === '') {
      return null;
    }

    if (!val.toString().match(/^[0-9]+(\.?[0-9]+)?$/)) {
      return { invalidNumber: true };
    }

    return null;
  }
}
