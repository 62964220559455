import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { PlatformService } from '@core/services';
import { BillingV2Service } from '@core/services/lingo2-account/billing-v2.service';
import { BillingApiSupplierResponseDto } from '@lingo2-billing-sdk/models';
import { BillingCorePayoutMethodEnum } from '@lingo2-billing-sdk/models/src/billing-core/billing-core-payout/types/enums/billing-core-payout-methods.enum';
import { BillingCorePayoutTypeEnum } from '@lingo2-billing-sdk/models/src/billing-core/billing-core-payout/types/enums/billing-core-payout-type.enum';
import { BillingCoreSupplierStatusEnum } from '@lingo2-billing-sdk/models/src/billing-core/billing-core-supplier/types/enums/billing-core-supplier-status.enum';
import { ChangableComponent } from '@models/changable.component';
import { ErrorNotificationService } from '@shared/error-notification/error-notification.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-payout-card',
  templateUrl: './payout-card.component.html',
  styleUrls: ['./payout-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PayoutCardComponent extends ChangableComponent {
  @Input()
  public set supplier(supplier: BillingApiSupplierResponseDto) {
    if (supplier) {
      this._supplier = supplier;
    }
    this.detectChanges();
  }

  public get supplier() {
    return this._supplier;
  }

  @Input() is_test: boolean;

  @Output() openHistory = new EventEmitter<string>();
  @Output() getMoney = new EventEmitter<BillingApiSupplierResponseDto>();
  @Output() onChange = new EventEmitter<string>();
  @Output() onEdit = new EventEmitter<BillingApiSupplierResponseDto>();
  @Output() onSetDefault = new EventEmitter<BillingApiSupplierResponseDto>();

  public _supplier: BillingApiSupplierResponseDto;
  public showAutoPayoutAlert = false;
  public showDeleteSupplierAlert = false;

  public constructor(
    public errorNotificationService: ErrorNotificationService,
    protected readonly billingV2Service: BillingV2Service,
    protected readonly cdr: ChangeDetectorRef,
    protected readonly platform: PlatformService,
  ) {
    super(cdr, platform);
  }

  public formatBalance(amount, currencyCode?) {
    const summ = Number(amount).toFixed(2);
    const currency = currencyCode ? currencyCode : this.supplier.currencyCode;
    return `${summ} ${currency}`;
  }

  public get status() {
    if (!this.supplier?.payoutAccount) {
      return 'error';
    } else {
      return this.supplier?.status.toLowerCase();
    }
  }

  public get showPopover() {
    if (this.supplier.status && this.supplier.payoutAccount) {
      return [BillingCoreSupplierStatusEnum.RESTRICTED].includes(this.supplier.status);
    } else {
      return true;
    }
  }

  public onOpenHistory(): void {
    this.openHistory.emit(this.supplier.providerId);
  }

  public onGetMoney(): void {
    this.getMoney.emit(this.supplier);
  }

  public deleteMethod() {
    this.billingV2Service
      .deleteSupplier(this.supplier.id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: () => {
          this.onChange.emit(String(new Date().getTime()));
        },
        error: (error) => {
          this.openDeleteSupplierAlert();
          this.errorNotificationService.captureError(error, 'OTHER-PROBLEM');
          this.detectChanges();
        },
      });
  }

  public get canPayout() {
    if (this.supplier.status && this.supplier.payoutAccount) {
      return [BillingCoreSupplierStatusEnum.CONFIRMED].includes(this.supplier.status);
    } else {
      return false;
    }
  }

  public editMethod() {
    this.onEdit.emit(this.supplier);
  }

  public setDefault() {
    this.onSetDefault.emit(this.supplier);
  }

  public get payoutAccount() {
    return this.supplier.payoutAccount;
  }

  public get bankName() {
    if (this.payoutAccount) {
      if (this.payoutAccount.bankAccount && this.payoutAccount.bankAccount.brand) {
        return this.payoutAccount.bankAccount.brand;
      } else {
        return '-';
      }
    } else {
      return '-';
    }
  }

  public get bankAccount() {
    if (this.payoutAccount) {
      if (this.payoutAccount.bankAccount && this.payoutAccount.bankAccount.label) {
        return '*******' + this.payoutAccount.bankAccount.label;
      } else {
        return '-';
      }
    } else {
      return '-';
    }
  }

  public get cardNumber() {
    if (this.payoutAccount) {
      if (this.payoutAccount.cardAccount && this.payoutAccount.cardAccount.label) {
        return '**** **** **** ' + this.payoutAccount.cardAccount.label;
      } else {
        return '-';
      }
    } else {
      return '-';
    }
  }

  public get bankInfo() {
    if (this.payoutAccount) {
      if (this.payoutAccount.bankAccount && this.payoutAccount.bankAccount.routingNumber) {
        return this.supplier.payoutAccount.bankAccount.routingNumber;
      } else {
        return '-';
      }
    } else {
      return '-';
    }
  }

  public get isDefault() {
    return this.supplier.isActive;
  }

  public openPayoutAlert() {
    this.showAutoPayoutAlert = true;
  }

  public closePayoutAlert() {
    this.showAutoPayoutAlert = false;
  }

  public openDeleteSupplierAlert() {
    this.showDeleteSupplierAlert = true;
  }

  public closDeleteSupplierAlert() {
    this.showDeleteSupplierAlert = false;
  }

  public get manualPayout() {
    return this.supplier.payoutTypesAvailable.includes(BillingCorePayoutTypeEnum.MANUAL);
  }

  public get autoPayout() {
    return this.supplier.payoutTypesAvailable.includes(BillingCorePayoutTypeEnum.AUTO);
  }

  public get typeCard() {
    return this.supplier.payoutMethodsAvailable.includes(BillingCorePayoutMethodEnum.CARD);
  }

  public get typeBank() {
    return this.supplier.payoutMethodsAvailable.includes(BillingCorePayoutMethodEnum.BANK);
  }

  public get isTestBeneficiary() {
    return this.is_test;
  }
}
