import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StepperModule } from '@app/content-editor/content-form-page/_components';
import { AlertModule } from '@core/components/alert/alert.module';
import { SpinnersModule } from '@core/components/spinners/spinners.module';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from '@shared/icon/icon.component';
import { OnUiButtonModule, OnUiDateModule, OnUiInputModule, OnUiSelectModule } from 'onclass-ui';
import { WithdrawMoneyFormComponent } from './withdraw-money-form.component';

@NgModule({
  declarations: [WithdrawMoneyFormComponent],
  exports: [WithdrawMoneyFormComponent],
  imports: [
    CommonModule,
    SpinnersModule,
    TranslateModule,
    AlertModule,
    FormsModule,
    OnUiButtonModule,
    OnUiDateModule,
    OnUiInputModule,
    OnUiSelectModule,
    ReactiveFormsModule,
    StepperModule,
    IconComponent,
  ],
})
export class WithdrawMoneyFormModule {}
