import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ModalModule } from '@core/components/modal/modal.module';
import { TranslateModule } from '@ngx-translate/core';
import { FormatPipeModule } from 'lingo2-ngx-date-fns';
import { OnUiButtonModule, OnUiCardMenuModule, OnUiPopoverModule } from 'onclass-ui';
import { PaymentHistoryComponent } from './payment-history.component';

@NgModule({
  declarations: [PaymentHistoryComponent],
  imports: [
    CommonModule,
    TranslateModule,
    ModalModule,
    OnUiButtonModule,
    OnUiCardMenuModule,
    OnUiPopoverModule,
    FormatPipeModule,
  ],
  exports: [PaymentHistoryComponent],
})
export class PaymentHistoryModule {}
