import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MobileModalModule } from '@core/components/mobile-modal/mobile-modal.module';
import { ModalModule } from '@core/components/modal/modal.module';
import { TranslateModule } from '@ngx-translate/core';
import {
  FinancePlanBuyCreditsFormModule,
  FinancePlanActivateFormModule,
  FinancePlanBuyCreditsFormV2Module,
  FinancePlanActivateFormV2Module,
} from 'lingo2-forms';
import { DateFnsModule } from 'lingo2-ngx-date-fns';
import { CurrentPlanCardModule } from '../components/current-plan-card/current-plan-card.module';
import { FinanceMainPageComponent } from './finance-main-page.component';

@NgModule({
  declarations: [FinanceMainPageComponent],
  imports: [
    CommonModule,
    TranslateModule,
    ModalModule,
    MobileModalModule,
    CurrentPlanCardModule,
    DateFnsModule,
    RouterModule,
    FinancePlanBuyCreditsFormModule,
    FinancePlanActivateFormModule,
    FinancePlanBuyCreditsFormV2Module,
    FinancePlanActivateFormV2Module,
  ],
  exports: [],
})
export class FinanceMainPageModule {}
