import { CommonModule, Location } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnInit,
  Output,
} from '@angular/core';
import { RouterModule } from '@angular/router';
import { BreakContractReasonData, classBreakReasonsData } from '@app/library/panes/my-classes-classrooms/constants';
import { UserServiceCardFullModule } from '@app/meetings/user-service-card-full/user-service-card-full.module';
import { AlertModule } from '@core/components/alert/alert.module';
import { MobileModalModule } from '@core/components/mobile-modal/mobile-modal.module';
import { ModalModule } from '@core/components/modal/modal.module';
import { UserServiceCard2Module, ServiceCardDisplayModeType } from '@core/components/service-card';
import { CoreModule } from '@core/core.module';
import { logger } from '@core/helpers/logger';
import { AccountService, ContextService, PlatformService, UserServiceContractsService } from '@core/services';
import { ChangableComponent } from '@models/changable.component';
import { LibraryRouter } from '@models/library.router';
import { TranslateModule } from '@ngx-translate/core';
import { UserServiceContractCheckoutDialogModule } from '@shared/checkout-wizards/user-service-contract-checkout-dialog';
import { ErrorNotificationService } from '@shared/error-notification/error-notification.service';
import { IconComponent } from '@shared/icon/icon.component';
import { BreakUserServiceContractRequest, UserService, UserServiceContract } from 'lingo2-models';
import { DeviceDetectorService } from 'ngx-device-detector';
import { OnUiButtonModule, OnUiCardMenuModule } from 'onclass-ui';
import { takeUntil } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-user-service-contract-card',
  templateUrl: './user-service-contract-card.component.html',
  styleUrls: ['./user-service-contract-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserServiceContractCardComponent extends ChangableComponent implements OnInit {
  @Input()
  public user_service_contract: UserServiceContract;

  @Input()
  public displayMode: ServiceCardDisplayModeType = 'wide';

  @Output()
  public contractChanged = new EventEmitter<boolean>();

  public paymentFormVisible = false;
  public breakAlertVisible = false;
  public userServiceCardFullInModal = false;
  public classBreakReasons: 'no_classes' | 'left_classes' | 'less_24_hours' = 'no_classes';
  public alertData: BreakContractReasonData = null;
  public debug$ = this.contextService.debug$;
  public openedUserService: UserService;
  public surfaceBlockDisplay: boolean;
  private originalUrl: string;
  public showCard: boolean;

  public constructor(
    public errorNotificationService: ErrorNotificationService,
    protected contextService: ContextService,
    public deviceService: DeviceDetectorService,
    public accountService: AccountService,
    private location: Location,
    protected contractsService: UserServiceContractsService,
    protected cdr: ChangeDetectorRef,
    protected platform: PlatformService,
  ) {
    super(cdr, platform);
  }

  public ngOnInit(): void {
    this.accountService
      .getUserById(this.user_service_contract.user_service.author_id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (user) => {
          this.user_service_contract.user_service.author = user;
          this.showCard = true;
          this.detectChanges();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
          this.showCard = false;
        },
      });
  }

  public printDebugInfo() {
    logger.log({
      user_service_contract: this.user_service_contract,
    });
  }

  public openPaymentForm() {
    this.paymentFormVisible = true;
    this.detectChanges();
  }

  public closePaymentForm() {
    this.paymentFormVisible = false;
    this.detectChanges();
  }

  // Успешно оплачен доступ к классу
  public onPaymentSucceed() {
    this.closePaymentForm();
    this.openedUserService = null;
    this.detectChanges();
  }

  public showBreakAlert() {
    // @TODO: Сделать вычисление по какой причине показывается алерт
    this.classBreakReasons = 'no_classes';
    if (this.user_service_contract.is.ongoing) {
      this.alertData = classBreakReasonsData['regular'][this.classBreakReasons];
    } else {
      this.alertData = classBreakReasonsData['course'][this.classBreakReasons];
    }

    this.breakAlertVisible = true;
    this.detectChanges();
  }

  public closeBreakAlert() {
    this.breakAlertVisible = false;
    this.detectChanges();
  }

  public breakUserServiceContract() {
    const request = new BreakUserServiceContractRequest({
      user_service_contract_id: this.user_service_contract.id,
      reason: null, // TODO
      comment: null, // TODO
    });
    this.contractsService
      .breakContract(request)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: () => {
          this.contractChanged.emit(true);
          this.closeBreakAlert();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'OTHER-PROBLEM');
        },
      });
  }

  public onIinViewportChange(e) {
    this.surfaceBlockDisplay = e;
    this.detectChanges();
  }

  public onToggleUserServiceInModal(userService: UserService = null) {
    this.openedUserService = userService;
    this.userServiceCardFullInModal = !this.userServiceCardFullInModal;
    this.setAndResetUrl();
    this.detectChanges();
  }

  private setAndResetUrl() {
    const url = LibraryRouter.userServiceRouteUniversal(this.openedUserService);
    const currentUrl = this.location.path(false);
    if (this.userServiceCardFullInModal) {
      if (currentUrl !== url) {
        if (!this.originalUrl) {
          this.originalUrl = currentUrl;
        }
        this.location.replaceState(url);
      }
    } else {
      if (this.originalUrl) {
        this.location.replaceState(this.originalUrl);
        this.originalUrl = null;
      }
    }
  }
}

@NgModule({
  declarations: [UserServiceContractCardComponent],
  imports: [
    CommonModule,
    CoreModule,
    TranslateModule,
    RouterModule,
    AlertModule,
    UserServiceCard2Module,
    OnUiButtonModule,
    OnUiCardMenuModule,
    UserServiceContractCheckoutDialogModule,
    MobileModalModule,
    ModalModule,
    UserServiceCardFullModule,
    IconComponent,
  ],
  exports: [UserServiceContractCardComponent],
})
export class UserServiceContractCardModule {}
