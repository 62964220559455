import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { ApplicationDialogsService } from '@app/shared/application-dialogs';
import { loadMyBillingPlan } from '@app/store/actions/profile.actions';
import { BillingService, FeaturesService } from '@core/services';
import { WebsocketService } from '@core/websocket';
import { ChangableComponent } from '@models/changable.component';
import { Store } from '@ngrx/store';
import { ErrorNotificationService } from '@shared/error-notification/error-notification.service';
import { getMyCurrency } from '@store/reducers/profile.reducer';
import {
  AccountPlan,
  BillingSubscriptionStatusEnum,
  CCurrencyAmount,
  Currency,
  CurrencyEnum,
  FeatureEnum,
  Parser,
  Plan,
  TenantEnum,
} from 'lingo2-models';
import { DeviceDetectorService } from 'ngx-device-detector';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-current-plan-card',
  templateUrl: './current-plan-card.component.html',
  styleUrls: ['./current-plan-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CurrentPlanCardComponent extends ChangableComponent implements OnInit {
  @Input()
  public set plan(plan: AccountPlan) {
    this._plan = plan;
    if (this._plan && !this.isAvailableSafeTransaction) {
      this._plan.plan.details.tariff_terms = this._plan.plan.details.tariff_terms.filter(
        (el) => el.label !== 'subscription-plans.terms.term4',
      );
    }
    this.expires_at = new Date(plan?.expires_at);
    this.expires_at_for_free_plan = new Date(new Date().setMonth(new Date().getMonth() + 1));
    [this._nextPlan] = this._plans?.filter((iplan) => iplan.id === this.plan?.next_plan_id);
  }

  public get plan(): AccountPlan {
    return this._plan;
  }

  @Input() set plans(plans: Plan[]) {
    this._plans = plans;
    [this._nextPlan] = this._plans?.filter((plan) => plan.id === this.plan?.next_plan_id);
  }

  public get plans() {
    return this._plans;
  }

  @Output() scrollEvent = new EventEmitter<boolean>();

  public expires_at: Date;
  public expires_at_for_free_plan: Date;
  public buyCreditsModalOpened: boolean;
  public cancelPlanModalOpened: boolean;
  public openResumePlanModal: boolean;
  public remainingCredits = 0;
  public tenant: TenantEnum;

  private _plan: AccountPlan;
  private _plans: Plan[] = [];
  private _nextPlan: Plan;
  private _myCurrency: CurrencyEnum = Currency.defaultCurrency;

  constructor(
    public errorNotificationService: ErrorNotificationService,
    public deviceService: DeviceDetectorService,
    private readonly store: Store,
    private billingService: BillingService,
    private features: FeaturesService,
    private dialogsService: ApplicationDialogsService,
    private websocket: WebsocketService,
    protected cdr: ChangeDetectorRef,
  ) {
    super(cdr);
  }

  ngOnInit(): void {
    this.websocket.onBalanceUpdate.pipe(takeUntil(this.destroyed$)).subscribe({
      next: () => this.getCreditAmount(),
      error: (error) => {
        this.errorNotificationService.captureError(error, 'SOCKET-ERROR');
      },
    });
    this.getCreditAmount();

    this.store
      .select(getMyCurrency)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (currency: CurrencyEnum) => {
          this._myCurrency = currency;
          this.detectChanges();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
          this._myCurrency = CurrencyEnum.usd;
          this.detectChanges();
        },
      });

    this.features.tenant$
      .pipe(
        filter((tenant) => !!tenant),
        takeUntil(this.destroyed$),
      )
      .subscribe({
        next: (tenant) => (this.tenant = tenant),
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
          this.tenant = TenantEnum.onclass_com;
        },
      });
  }

  public scroll() {
    this.scrollEvent.emit(true);
  }

  public isMeVersion() {
    return this.features.tenant === TenantEnum.onclass_me;
  }

  public upgradePlan() {
    this.dialogsService.openPaywallModal({
      caller: 'app-current-plan-card',
      reason: 'buy or upgrade plan',
    });
  }

  public toggleResumePlan() {
    this.openResumePlanModal = !this.openResumePlanModal;
    this.detectChanges();
  }

  public getPlanForCredits() {
    this.closeBuyCreditsModal();
    this.dialogsService.openPaywallModal({
      caller: 'app-current-plan-card',
      reason: 'get better credits price',
    });
  }

  public openBuyCreditsModal() {
    this.buyCreditsModalOpened = true;
    this.detectChanges();
  }

  public closeBuyCreditsModal() {
    this.buyCreditsModalOpened = false;
    this.getCreditAmount();
    this.detectChanges();
  }

  public openCancelPlanModal() {
    this.cancelPlanModalOpened = true;
    this.detectChanges();
  }

  public closeCancelPlanModal() {
    this.dialogsService.closePaywallModal();
    this.cancelPlanModalOpened = false;
    this.detectChanges();
  }

  public onCancelPlanFinished() {
    this.dialogsService.closePaywallModal();
    this.cancelPlanModalOpened = false;
    this.store.dispatch(loadMyBillingPlan());
    this.detectChanges();
  }

  public clearVariables(line: string) {
    const begin = '{{ ';
    const end = ' }}';
    if (!this._plan.plan || !this._plan.plan.details) {
      return line;
    }
    let bracedWord = Parser.getBracedWord(line, begin, end).word;
    while (bracedWord) {
      const replace = ' ';
      line = Parser.replaceBracedWord(line, bracedWord, replace, begin, end);
      bracedWord = Parser.getBracedWord(line, begin, end).word;
    }
    return line;
  }

  public clearPercents(line: string) {
    return line.replace(' %', '');
  }

  public clearText(line: string) {
    const begin = '{{ ';
    const end = ' }}';
    if (!this._plan.plan || !this._plan.plan.details) {
      return line;
    }
    let bracedWord = Parser.getBracedWord(line, begin, end).word;
    let isCommission = false;
    if (bracedWord === 'safe_trade_commission') {
      isCommission = true;
    }
    const details = this._plan.plan.details;
    while (bracedWord) {
      line =
        details[bracedWord] === undefined
          ? bracedWord
          : isCommission
          ? (100 - Number(details[bracedWord])).toString()
          : // eslint-disable-next-line @typescript-eslint/no-base-to-string
            details[bracedWord].toString();
      // eslint-disable-next-line @typescript-eslint/no-base-to-string
      bracedWord = Parser.getBracedWord(line, begin, end).word;
    }
    return line;
  }

  public percent(line: string): string {
    const list = line.split('').reverse();
    const [percent] = list;
    if (percent === '%') {
      return '%';
    }
    return '';
  }

  public get nextPlan(): Plan {
    return this._nextPlan;
  }

  public get notActiveSubscription() {
    return this.plan.subscription_status === BillingSubscriptionStatusEnum.not_active;
  }

  public get reasonForDeactivation() {
    return this.plan.reason_for_deactivation;
  }

  public getFormattedPrice() {
    let currencyAmount = new CCurrencyAmount({
      amount: 0,
      currency_id: Currency.defaultCurrency,
    });
    if (this._nextPlan?.priceTier) {
      currencyAmount = this._nextPlan.priceTier.getForCurrency(this._myCurrency);
    }
    return currencyAmount.formatFixed();
  }

  public get isFreePlan() {
    return !this._nextPlan?.priceTier;
  }

  public get isAvailableSafeTransaction() {
    return this.features.isAvailable(FeatureEnum.safe_transaction);
  }

  public get isMaxPlan() {
    const currentPlanOrder = this.plan?.plan?.order;
    let biggerPlans = 0;
    this.plans?.forEach((plan) => {
      if (plan.order > currentPlanOrder) {
        biggerPlans++;
      }
    });
    return biggerPlans <= 0;
  }

  public getCreditAmount() {
    this.billingService
      .getAvailableAmount(CurrencyEnum.credits)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (amount) => {
          this.remainingCredits = amount;
          this.detectChanges();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
          this.remainingCredits = 0;
          this.detectChanges();
        },
      });
  }

  public trackByFn(index) {
    return index;
  }
}
