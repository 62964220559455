<app-alert type="warning" [btnPosition]="'center'">
  <ng-container alert-icon>
    <app-icon width="34" height="36" file="emoji" icon="think"></app-icon>
  </ng-container>
  <ng-container alert-title>{{ 'payout.change-alert.title' | translate }}</ng-container>
  <ng-container alert-body>{{ 'payout.change-alert.description' | translate }}</ng-container>
  <ng-container alert-buttons>
    <on-ui-button label="payout.change-alert.cancel"
                  state="default"
                  class="transparent center"
                  (onclick)="onCancel()">
    </on-ui-button>
    <on-ui-button label="payout.change-alert.change"
                  state="default"
                  class="primary center"
                  (onclick)="onChange()">
    </on-ui-button>
  </ng-container>
</app-alert>
