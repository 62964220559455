import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { BillingV2Service } from '@core/services/lingo2-account/billing-v2.service';
import { BillingApiTransactionResponseDto } from '@lingo2-billing-sdk/models';
import { ChangableComponent } from '@models/changable.component';
import { CurrencyEnum } from 'lingo2-models';
import { DeviceDetectorService } from 'ngx-device-detector';

export interface PaymentHistoryData {
  id: string;
  date: Date | number;
  operationTitle: string;
  operationDesc: string;
  amount: number;
  currency: CurrencyEnum;
  operationType: 'debit' | 'credit';
  status: string;
}

@Component({
  selector: 'app-payment-history',
  templateUrl: './payment-history.component.html',
  styleUrls: ['./payment-history.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentHistoryComponent extends ChangableComponent implements OnInit {
  @Input() history: BillingApiTransactionResponseDto[];

  @Input() type: 'modal' | 'page' = 'page';

  public constructor(
    private billingV2Service: BillingV2Service,
    protected readonly cdr: ChangeDetectorRef,
    public deviceService: DeviceDetectorService,
  ) {
    super(cdr);
  }

  ngOnInit(): void {}

  public getDateFromTransaction(date: Date) {
    return new Date(date);
  }

  public formatAmount(operation: BillingApiTransactionResponseDto) {
    // const symbol = operation.ledgerType === BillingCoreLedgerTypeEnum.DEBIT ? '+' : '-';
    return this.billingV2Service.formatAmount(operation.amount, operation.currencyCode);
  }

  public trackByFn(index) {
    return index;
  }
}
