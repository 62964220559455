import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormControl,
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { BrowserModule, DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { IStep } from '@app/content-editor/content-form-page/_components/stepper';
import { IControlOption } from '@app/shared';
import { logger } from '@core/helpers/logger';
import {
  ConfigService,
  ContextService,
  CrispChatService,
  LanguageService,
  PlatformService,
  ScreenService,
  UtilsService,
} from '@core/services';
import { BillingV2Service, IFinanceCardCreateNewOptions } from '@core/services/lingo2-account/billing-v2.service';
import { environment } from '@env/environment';
import {
  BillingApiMerchantRequirementResponseDto,
  BillingApiMerchantResponseDto,
  BillingApiPaymentResponseDto,
  BillingApiSupplierDto,
  BillingApiSupplierResponseDto,
} from '@lingo2-billing-sdk/models';
import { BillingCorePaymentStatusEnum } from '@lingo2-billing-sdk/models/src/billing-core/billing-core-payment/types/enums/billing-core-payment-status.enum';
import { BillingCorePayoutMethodEnum } from '@lingo2-billing-sdk/models/src/billing-core/billing-core-payout/types/enums/billing-core-payout-methods.enum';
import { BillingCoreSupplierBusinessTypeEnum } from '@lingo2-billing-sdk/models/src/billing-core/billing-core-supplier/types/enums/billing-core-supplier-business-type.enum';
import { BillingCoreSupplierStatusEnum } from '@lingo2-billing-sdk/models/src/billing-core/billing-core-supplier/types/enums/billing-core-supplier-status.enum';
import { ChangableComponent } from '@models/changable.component';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { PaymentFail } from '@shared/checkout-wizards/components/payment-widget';
import { PaymentWidgetMerchantProcessingService } from '@shared/checkout-wizards/components/payment-widget/payment-widget-merchant-processing/payment-widget-merchant-processing.service';
import { ErrorNotificationService } from '@shared/error-notification/error-notification.service';
import { loadCountries } from '@store/actions/data.actions';
import { loadMe, loadMyProfileSuccess } from '@store/actions/profile.actions';
import { Country, CSchool, User, UserProfile } from 'lingo2-models';
import { isEqual, cloneDeep } from 'lodash';
import { DeviceDetectorService } from 'ngx-device-detector';
import { CountryISO, NgxIntlPhoneInputModule } from 'ngx-intl-phone-input';
import { OnUiButtonModule, OnUiButtonState, OnUiDateModule, OnUiFormFieldErrorModule, OnUiInputModule, OnUiSelectModule, OnUiSelectSearchModule } from 'onclass-ui';
import { combineLatest, throwError } from 'rxjs';
import { catchError, debounceTime, takeUntil, tap } from 'rxjs/operators';
import { StepperModule } from '../../../../content-editor/content-form-page/_components/stepper/stepper.module';
import { SpinnersModule } from '../../../../core/components/spinners/spinners.module';
import { PaymentWidgetMerchantProcessingModule } from '../../../../shared/checkout-wizards/components/payment-widget/payment-widget-merchant-processing/payment-widget-merchant-processing.module';
import { IconComponent } from '../../../../shared/icon/icon.component';
import { AlertModule } from "../../../../core/components/alert/alert.module";
import { CommonModule } from '@angular/common';

/** Базовые поля для всех стран */
const baseFields = ['firstName', 'lastName', 'email', 'phone', 'dob', 'countryCode', 'businessType'];

export enum StepsEnum {
  beneficiary = 1,
  payout_method = 2,
}

export const STEPS: IStep[] = [
  {
    id: StepsEnum.beneficiary,
    label: 'Beneficiary',
    route: null,
    touched: false,
    invalid: null,
  },
  {
    id: StepsEnum.payout_method,
    label: 'Payout method',
    route: null,
    touched: false,
    invalid: null,
  },
];

const supplierFieldFormetters = {
  phone: (obj: any) => obj?.e164Number,
  countryCode: (obj: any) => obj?.toUpperCase(),
};

const countryTitle = (country: Country): string => {
  const parts: string[] = [country.title];
  if (country.title && country.title_native !== country.title) {
    parts.push(`(${country.title_native})`);
  }
  return parts.join(' ');
};

interface PreviewInterface {
  [key: string]: string | SafeResourceUrl;
}

interface ImageToSendInterface {
  [key: string]: string;
}

interface ImageExistInterface {
  [key: string]: boolean;
}

@Component({
  selector: 'app-finance-card-create-new',
  standalone: true,
  templateUrl: './finance-card-create-new.component.html',
  styleUrls: ['./finance-card-create-new.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    StepperModule,
    OnUiButtonModule,
    ReactiveFormsModule,
    SpinnersModule,
    PaymentWidgetMerchantProcessingModule,
    IconComponent,
    AlertModule,
    OnUiFormFieldErrorModule,
    OnUiInputModule,
    OnUiDateModule,
    NgxIntlPhoneInputModule,
    OnUiSelectModule,
    OnUiSelectSearchModule,
],
})
export class FinanceCardCreateNewComponent extends ChangableComponent implements OnInit {
  /** Опции создания/редактирования поставщика */
  @Input()
  public set options(value: IFinanceCardCreateNewOptions) {
    if (value) {
      this._options = value;
    }
  }

  public get options() {
    return this._options;
  }

  @Input() school: CSchool;

  @Output() finished = new EventEmitter<boolean>();

  public steps: IStep[] = STEPS;
  public activeIndex: StepsEnum = StepsEnum.beneficiary;
  public stepsEnum = StepsEnum;

  public btnState: OnUiButtonState = 'default';

  public _options: IFinanceCardCreateNewOptions;
  private supplier: BillingApiSupplierResponseDto;

  public supplierForm: UntypedFormGroup;
  public accountForm: UntypedFormGroup;
  public submittedSupplier = false;
  public submittedAccount = false;
  public supplierDataLoaded = false;
  public accountDataLoaded = false;
  public supplierFields: BillingApiMerchantRequirementResponseDto[];
  public accountFields: BillingApiMerchantRequirementResponseDto[];

  public merchant: BillingApiMerchantResponseDto;

  public countriesOptions: IControlOption[];
  public businessTypeOptions: IControlOption[];
  public countries: Country[];
  public countryISO = CountryISO;
  public selectedCountry: CountryISO;

  public maxDate = this.getMaxDate();

  public employmentType: BillingCoreSupplierBusinessTypeEnum;

  public previewUrl: PreviewInterface = null;
  public payloadMedia: ImageToSendInterface = null;
  public _haveUploadedImage: ImageExistInterface = null;
  public uploadError: boolean;
  public _isMediaPrepared: ImageExistInterface = null;

  public isFinalStep = false;
  public isFirstStep = true;

  public me: User;
  public profile: UserProfile;
  public mode: 'edit' | 'create' = 'create';
  public error: any;
  public showFields = false;
  public showBusinessTypeField = false;
  public showLoader = false;
  public approvedCountriesFromApi: string[];
  public fieldErrors: any = {};
  public invalidForm = false;

  public attachPayoutCardPayment: BillingApiPaymentResponseDto;
  public attachPayoutCardResult: 'success' | 'fail' = null;
  public errorDescription = '';

  public onboardingFrameUrl: SafeResourceUrl = '';
  public frameLoaded = false;
  public currentLang: string;

  public constructor(
    public errorNotificationService: ErrorNotificationService,
    protected fb: UntypedFormBuilder,
    protected configService: ConfigService,
    protected screenService: ScreenService,
    protected utilsService: UtilsService,
    protected merchantService: PaymentWidgetMerchantProcessingService,
    protected crispChat: CrispChatService,
    protected contextService: ContextService,
    protected sanitizer: DomSanitizer,
    protected store: Store,
    public deviceService: DeviceDetectorService,
    protected cdr: ChangeDetectorRef,
    protected platform: PlatformService,
    protected billingV2Service: BillingV2Service,
    protected languageService: LanguageService,
    protected translate: TranslateService,
  ) {
    super(cdr, platform);
  }

  public ngOnInit() {
    combineLatest([
      this.contextService.me$,
      this.contextService.profile$,
      this.configService.countries$,
      this.languageService.language$,
    ])
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: ([me, profile, countries, currentLang]) => {
          this.me = me;
          this.profile = profile;
          this.currentLang = currentLang.code;
          this.countries = countries;
          this.countriesOptions = this.countriesToOptions(this.countries, []);
          this.getSupportedCountries();
          this.patchSupplierFormInputs(this.me, this.profile);
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
          this.store.dispatch(loadMe());
          this.store.dispatch(loadCountries({ locale: this.me.ui_language }));
        },
      });

    this.supplierForm.valueChanges
      .pipe(debounceTime(300))
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: () => {
          if (!this.selectedCountry || this.selectedCountry !== this.supplierForm.get('countryCode').value) {
            this.selectedCountry = this.supplierForm.get('countryCode').value;
            this.showFields = true;
            this.getAdditionalFieldsForSupplierForm();
            this.detectChanges();
          }

          if (!this.employmentType || this.employmentType !== this.supplierForm.get('businessType').value) {
            this.employmentType = this.supplierForm.get('businessType').value;
            this.detectChanges();
          }

          Object.keys(this.supplierForm.controls).forEach((key) => {
            this.fieldErrors[key] = this.supplierForm.controls[key].invalid;
          });
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'OTHER-PROBLEM');
          this.selectedCountry = null;
          this.showFields = false;
          this.fieldErrors = null;
          this.employmentType = BillingCoreSupplierBusinessTypeEnum.INDIVIDUAL;
        },
      });

    this.prepare();
  }

  /** Заполняем форму данными про пользователя (дефолтные поля) */
  public patchSupplierFormInputs(me: User, profile: UserProfile) {
    this.supplierForm = this.fb.group({
      dob: [profile?.birth_date || new Date(), [Validators.required]],
      countryCode: [this.selectedCountry, [Validators.required, Validators.minLength(1)]],
      businessType: [BillingCoreSupplierBusinessTypeEnum.INDIVIDUAL, [Validators.required]],
      firstName: [me.first_name, [Validators.required]],
      lastName: [me.last_name, [Validators.required]],
      phone: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
    });

    this.accountForm = this.fb.group({});
    this.detectChanges();
  }

  /** Добавляем поля в форму полученные из бека */
  public patchSupplierFormAdvanced(data: BillingApiMerchantRequirementResponseDto[]) {
    Object.keys(this.supplierForm.controls).forEach((key: string) => {
      if (!baseFields.includes(key)) {
        this.supplierForm.removeControl(key);
      }
    });
    data.forEach((field, index) => {
      this.supplierForm.addControl(field.key, new FormControl(''));
      if (field.regex) {
        this.supplierForm.controls[field.key].addValidators([Validators.required, Validators.pattern(field.regex)]);
      } else {
        this.supplierForm.controls[field.key].addValidators([Validators.required]);
      }
    });
    this.supplierForm.updateValueAndValidity();

    if (this.modeEdit) {
      if (this.supplierFields) {
        const advancedValues = {};
        this.supplierFields.forEach((item) => {
          let supplierExtraField = this.supplier.merchant.supplier.find((data) => data.name === item.key);
          if (!supplierExtraField || supplierExtraField === undefined) {
            supplierExtraField = {
              value: '',
              name: item.key,
            };
          }

          if (item.type === 'FILE') {
            this._haveUploadedImage = {
              ...this._haveUploadedImage,
              [supplierExtraField.name]: supplierExtraField.value && supplierExtraField.value !== '',
            };
          }
          advancedValues[supplierExtraField.name] = supplierExtraField.value;
        });
        this.supplierForm.patchValue(advancedValues);
      }
    }
    this.showFields = true;
    this.showLoader = false;
    this.detectChanges();
  }

  /** Добавляем поля в форму счета полученные из бека */
  public patchSupplierAccountFormAdvanced(data: BillingApiMerchantRequirementResponseDto[]) {
    Object.keys(this.accountForm.controls).forEach((key: string) => {
      if (!baseFields.includes(key)) {
        this.accountForm.removeControl(key);
        this.accountForm.updateValueAndValidity();
      }
    });
    data.forEach((field) => {
      if (field.regex) {
        this.accountForm.addControl(
          field.key,
          new FormControl('', [Validators.required, Validators.pattern(field.regex)]),
        );
      } else {
        this.accountForm.addControl(field.key, new FormControl('', [Validators.required]));
      }
    });
    this.accountForm.updateValueAndValidity();

    if (this.modeEdit) {
      if (this.accountFields) {
        const advancedValues = {};
        this.accountFields.forEach((item) => {
          const supplierExtraField = this.supplier.merchant.payoutAccount.find((data) => data.name === item.key);
          advancedValues[supplierExtraField.name] = supplierExtraField.value;
        });
        this.accountForm.patchValue(advancedValues);
      }
    }
    this.showFields = true;
    this.showLoader = false;
    this.detectChanges();
  }

  /** Флаг загрузились ли все данные и поля формы получены */
  public get canShowFields() {
    return this.showFields && !this.showLoader;
  }

  /** Конвертация полученного списка стран в опции для select */
  public countriesToOptions(countries: Country[], approvedCountries: string[]): IControlOption[] {
    let workedList: Country[] = [];
    if (approvedCountries.length) {
      workedList = countries.filter((country) => approvedCountries.includes(country.alpha2Code));
    } else {
      workedList = countries;
    }
    return workedList.map((country) => ({
      title: countryTitle(country),
      value: country.alpha2Code,
    }));
  }

  /** Конвертация типов занятости в опции для select */
  public employmentToOptions(employments: string[]) {
    const workedList: IControlOption[] = [];
    employments.forEach((employment) => {
      workedList.push({
        title: this.translate.instant('profile-form.about.business_types.' + employment.toLowerCase()),
        value: employment,
      });
    });
    return workedList;
  }

  public isInvalidSupplierForm(formControlName: string): boolean {
    return this.supplierForm.get(formControlName)?.invalid;
  }

  public onHighlightErrors(isErrorsVisible: boolean) {
    logger.log('SHOW ERROR? - ', isErrorsVisible);
  }

  /** Сохраняем саплеера */
  public sendSupplier() {
    this.supplierForm.markAsTouched();
    this.submittedSupplier = true;
    if (this.supplierForm.invalid) {
      this.invalidForm = true;
      Object.keys(this.supplierForm.controls).forEach((key) => {
        this.fieldErrors[key] = this.supplierForm.controls[key].invalid;
      });
      return;
    } else {
      this.invalidForm = true;
      this.steps.find((step) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
        if (step.id === StepsEnum.beneficiary) {
          step.touched = true;
          step.invalid = false;
        }
      });
    }

    if (this.activeIndex === StepsEnum.beneficiary) {
      this.saveSupplierForm();
    }
  }

  /** Следующий шаг формы */
  public doNextStep() {
    if (this.itsCardPayout) {
      this.getAttachPayoutCardInfo();
    }
    this.activeIndex++;
    this.calcSteps();
  }

  public finishForm() {
    this.btnState = 'default';
    this.finished.emit(true);
    this.detectChanges();
  }

  /** Предыдущий шаг формы */
  public doPrevStep() {
    this.activeIndex--;
    this.calcSteps();
  }

  private getAttachPayoutCardInfo() {
    if (!this.supplier || !this.merchant) {
      return;
    }

    this.billingV2Service
      .attachPayoutCard({ contractorId: this.merchant.id, supplierId: this.supplier.id })
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (payment) => {
          switch (payment.status) {
            case BillingCorePaymentStatusEnum.INTENT: // Ручная оплата у мерчанта
              this.attachPayoutCardPayment = payment;
              if (this.itsCardPayout) {
                this.btnState = 'progress';
              }
              this.setTimeout(() => this.merchantService.pay(), 500);
              break;

            case BillingCorePaymentStatusEnum.SUCCEEDED: // Сразу оплачен
              this.onSuccessAttachCard();
              break;

            case BillingCorePaymentStatusEnum.FAILED:
              this.btnState = 'default';
              this.attachPayoutCardPayment = null;
              this.onFailAttachCard({ message: JSON.stringify(payment) });
              break;
          }
          this.detectChanges();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
          this.btnState = 'default';
          this.attachPayoutCardPayment = null;
          this.onFailAttachCard({ message: JSON.stringify(error?.message) });
        },
      });
  }

  /**
   * 1. Загрузка поставщика по id (только если редактируется)
   * 2. Загрузка требований к форме
   */
  private prepare() {
    if (!this.options) {
      return;
    }

    if (this.options.supplierId) {
      this.mode = 'edit';
      this.billingV2Service
        .getSupplier(this.options.supplierId)
        .pipe(takeUntil(this.destroyed$))
        .subscribe({
          next: (supplier) => {
            if (!supplier) {
              throw new Error(`Supplier not found`);
            }
            this.supplier = supplier;
            this.patchSupplierForm();
            this.patchAccountForm();
          },
          error: (error) => {
            this.errorNotificationService.captureError(error, 'PAYMENT-PROBLEM');
          },
        });
    } else {
      this.mode = 'create';
    }
  }

  /** Получить доп поля для формы саплаера */
  public getAdditionalFieldsForSupplierForm() {
    this.showLoader = true;
    this.billingV2Service
      .getSupplierRequirements(this.options.where.providerId, this.selectedCountry, 'ACCOUNT')
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (data) => {
          this.supplierFields = data;
          this.patchSupplierFormAdvanced(data);
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
          this.showFields = true;
          this.showLoader = false;
        },
      });
  }

  /** Получить доп поля для формы счета */
  public getAdditionalFieldsForSupplierAccountForm() {
    this.showLoader = true;
    this.billingV2Service
      .getSupplierRequirements(this.options.where.providerId, this.selectedCountry, 'ACCOUNT')
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (data) => {
          this.accountFields = data;
          this.patchSupplierAccountFormAdvanced(data);
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
          this.showFields = true;
          this.showLoader = false;
        },
      });
  }

  /** Сохранить форму пользователя (шаг 1) */
  private saveSupplierForm() {
    this.btnState = 'progress';
    const json = this.supplierForm.getRawValue();
    let payload: BillingApiSupplierDto = {
      merchant: {
        supplier: [],
        payoutAccount: [],
      },
    } as any;
    for (const key in json) {
      if (baseFields.includes(key)) {
        payload[key] = supplierFieldFormetters[key] ? supplierFieldFormetters[key](json[key]) : json[key];
      } else {
        this.supplierFields.forEach((field, index) => {
          if (field.key === key) {
            (payload.merchant.payoutAccount as any[]).push({
              name: key,
              value: supplierFieldFormetters[key] ? supplierFieldFormetters[key](json[key]) : json[key],
            });
          }
        });
      }
    }
    if (this.supplier) {
      const sup = new BillingApiSupplierDto();
      let checksup = new BillingApiSupplierDto();
      sup.contractorId = this.supplier.contractorId;
      sup.providerId = this.supplier.providerId;
      sup.isActive = this.supplier.isActive;
      sup.countryCode = this.supplier.countryCode;
      sup.businessType = this.supplier.businessType;
      if (this.isIndividualEmployment) {
        sup.dob = this.supplier.dob;
        sup.lastName = this.supplier.lastName;
      }
      sup.email = this.supplier.email;
      sup.phone = this.supplier.phone;
      sup.firstName = this.supplier.firstName;

      payload = {
        ...sup,
        ...payload,
      };

      checksup = {
        ...sup,
        merchant: {
          supplier: this.supplier.merchant?.supplier ? this.supplier.merchant?.supplier : null,
        },
      };

      if (isEqual(checksup, payload)) {
        this.btnState = 'default';
        this.doNextStep();
        this.openStripeOnboarding('clear');
        this.detectChanges();
        return;
      }

      this.billingV2Service
        .updateSupplier(this.supplier.id, payload, this.school?.id)
        .pipe(takeUntil(this.destroyed$))
        .subscribe({
          next: (supplier) => {
            Object.assign(this.supplier, supplier);
            this.btnState = 'default';
            this.doNextStep();
            this.openStripeOnboarding('clear');
            this.detectChanges();
          },
          error: (error) => {
            this.errorNotificationService.captureError(error, 'SAVE-PROBLEM');
            this.error = error;
            this.btnState = 'default';
            logger.debug('ERROR ON UPDATE SUPPLIER: -> ' + JSON.stringify(error));
            this.detectChanges();
          },
        });
    } else {
      payload.providerId = this.options.where.providerId;
      if (this.isIndividualEmployment) {
        payload.dob = this.convertDateToUTC(payload.dob);
      }
      this.billingV2Service
        .createSupplier(payload, this.school?.id)
        .pipe(takeUntil(this.destroyed$))
        .subscribe({
          next: (supplier) => {
            this.options.where = null;
            this.supplier = supplier;
            this.doNextStep();
            this.openStripeOnboarding('clear');
            this.btnState = 'default';
            this.detectChanges();
          },
          error: (error) => {
            this.errorNotificationService.captureError(error, 'SAVE-PROBLEM');
            this.btnState = 'default';
            this.error = error;
            logger.debug('ERROR ON CREATE SUPPLIER: -> ' + JSON.stringify(error));
            this.detectChanges();
            // this.finished.emit(false);
          },
        });
    }
  }

  public convertDateToUTC(date: Date) {
    if (typeof date === 'string') {
      date = new Date(date);
    }
    if (this.screenService.isSafari) {
      const tempDate = cloneDeep(date);
      tempDate.setMinutes(tempDate.getMinutes() - tempDate.getTimezoneOffset());
      return tempDate;
    } else {
      return new Date(date + 'Z');
    }
  }

  /** Вставка данных в форму пользователя (шаг 1) */
  private patchSupplierForm() {
    let values = {};
    if (this.supplier) {
      values = {
        countryCode: this.supplier?.countryCode,
        businessType: this.supplier?.businessType,
        dob: this.supplier?.dob,
        firstName: this.supplier?.firstName,
        lastName: this.supplier?.lastName,
        phone: this.supplier?.phone,
        email: this.supplier?.email,
      };
    }
    this.supplierForm.patchValue(values);
    this.supplierDataLoaded = true;
    this.detectChanges();
  }

  /** Вставка данных в форму счет (шаг 2) */
  private patchAccountForm() {
    const values = {};
    if (this.accountFields) {
      this.accountFields.forEach((item) => {
        const supplierExtraField = this.supplier.merchant.payoutAccount.filter((data) => data.name === item.key);
        values[item.key] = supplierExtraField['value'];
      });
    }
    this.accountForm.patchValue(values);
    this.accountDataLoaded = true;
    this.detectChanges();
  }

  /** Список поддерживаемых стран */
  public getSupportedCountries() {
    this.billingV2Service
      .getMerchants()
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (merchants) => {
          this.merchant = merchants.find((merchant) => merchant.id === this.options.where.providerId);
          this.approvedCountriesFromApi = this.merchant.payoutSupportCountries;
          this.countriesOptions = this.countriesToOptions(this.countries, this.approvedCountriesFromApi);
          this.businessTypeOptions = this.employmentToOptions(this.merchant.allowBusinessType);
          this.detectChanges();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
          this.merchant = null;
          this.approvedCountriesFromApi = ['US'];
          this.countriesOptions = this.countriesToOptions(this.countries, this.approvedCountriesFromApi);
          this.businessTypeOptions = this.employmentToOptions(this.merchant.allowBusinessType);
        },
      });
  }

  public regexToMask(regex) {
    return this.utilsService.regexToMask(regex);
  }

  /** Максимальная дата рождения (18+) */
  public getMaxDate() {
    return new Date(new Date().setFullYear(new Date().getFullYear() - 18));
  }

  /** Если форму открыли для редактирования */
  public get modeEdit() {
    return this.mode === 'edit';
  }

  /** Если форму открыли для создания */
  public get modeCreate() {
    return this.mode === 'create';
  }

  /** Статус бенефициара */
  public get status() {
    return this.supplier.status;
  }

  /** Генерация лейбла для поля */
  public getFieldLabel(field) {
    if (field.label) {
      return field.label.trim();
    } else {
      let str = field.key;
      if (str.includes('.')) {
        str = str.replace('.', ' ').trim();
      }
      if (str.includes('_')) {
        str = str.replace('_', ' ').trim();
      }
      return this.capitalizeFirstLetter(str);
    }
  }

  capitalizeFirstLetter(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  public isHiddenField(fieldKey: string) {
    if (this.modeEdit) {
      return ['businessType', 'cardPayments'].includes(fieldKey);
    } else {
      return false;
    }
  }

  /** Заблокированное поле */
  public isLockedField(fieldKey: string) {
    if (this.modeEdit) {
      if (!this.supplier.merchant.supplier.length || !this.supplier.payoutAccount) {
        if (!this.supplier.merchant.supplier.length) {
          return false;
        } else {
          return ['firstName', 'lastName', 'dob', 'countryCode'].includes(fieldKey);
        }
      }
      if (this.status === BillingCoreSupplierStatusEnum.PENDING) {
        return true;
      } else if (
        this.status === BillingCoreSupplierStatusEnum.RESTRICTED ||
        this.status === BillingCoreSupplierStatusEnum.CONFIRMED
      ) {
        return ['firstName', 'lastName', 'dob', 'countryCode'].includes(fieldKey);
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  /** Перерасчет шагов визарда */
  public calcSteps() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
    if (this.activeIndex === this.steps.length - (this.steps.length - 1)) {
      this.isFinalStep = false;
      this.isFirstStep = true;
    } else {
      this.isFirstStep = false;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
      if (this.activeIndex === this.steps.length) {
        this.isFinalStep = true;
      }
    }
  }

  /** Перейти на шаг визарда */
  public goToStep(step: IStep) {
    this.activeIndex = step.id;

    if (this.activeIndex === StepsEnum.beneficiary) {
    } else {
      this.openStripeOnboarding('clear');
    }
    this.btnState = 'default';
    this.calcSteps();
    this.detectChanges();
  }

  /** Вызов сапорт чата */
  public callChat() {
    this.crispChat.openChat();
  }

  public closeAlert() {
    this.error = null;
  }

  public haveUploadedImage(key: string) {
    return this._haveUploadedImage && this._haveUploadedImage[key];
  }

  public isMediaPrepared(key: string) {
    return this._isMediaPrepared && this._isMediaPrepared[key];
  }

  public get isForNotRussia() {
    if (this.merchant.unSupportCountries) {
      return (this.merchant.unSupportCountries as any[]).includes('RU');
    } else {
      if (this.merchant.supportedCountries) {
        return !(this.merchant.supportedCountries as any[]).includes('RU');
      } else {
        return true;
      }
    }
  }

  public get itsCardPayout() {
    return this.supplier?.payoutMethodsAvailable.includes(BillingCorePayoutMethodEnum.CARD);
  }

  public onSuccessAttachCard() {
    this.attachPayoutCardPayment = null;
    this.attachPayoutCardResult = 'success';
    this.btnState = 'default';
    this.detectChanges();
    this.setTimeout(() => {
      this.finished.emit(true);
    }, 5000);
  }

  public onFailAttachCard(event: PaymentFail) {
    this.errorDescription = event.message ? event.message : JSON.stringify(event);
    this.attachPayoutCardPayment = null;
    this.attachPayoutCardResult = 'fail';
  }

  public openStripeOnboarding(type: 'clear' | 'bank') {
    if (!this.merchant || !this.supplier) {
      return;
    }

    const url = `${environment.stripe_iframe_host}/${this.merchant.id}/connect?supplierId=${this.supplier.id}&bank=${
      type === 'bank'
    }`;

    this.onboardingFrameUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    this.detectChanges();
  }

  public frameLoad(event) {
    this.frameLoaded = true;
    this.detectChanges();
  }

  public get isIndividualEmployment() {
    return this.employmentType === BillingCoreSupplierBusinessTypeEnum.INDIVIDUAL;
  }

  public isFieldWithError(fieldName: string) {
    if (!this.supplierForm) {
      return false;
    }
    return this.fieldErrors[fieldName] && this.invalidForm;
  }

  public trackByFn(index) {
    return index;
  }
}
