import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FilterContentComponent } from '@app/library/panes/filter-content/filter-content.component';
import { PaginatorModule } from '@app/shared';
import { MobileModalModule } from '@core/components/mobile-modal/mobile-modal.module';
import { ModalModule } from '@core/components/modal/modal.module';
import { NoContentStubComponent } from '@core/components/no-content-stub/no-content-stub.component';
import { PaginationModule } from '@core/components/pagination/pagination.module';
import { SpinnersModule } from '@core/components/spinners/spinners.module';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from '@shared/icon/icon.component';
import { PaymentHistoryModule } from '@shared/payment-history/payment-history.module';
import { DateFnsModule, FormatPipeModule } from 'lingo2-ngx-date-fns';
import { OnUiButtonModule, OnUiDateModule, OnUiSelectModule } from 'onclass-ui';
import { FinanceHistoryPageComponent } from './finance-history-page.component';
import { TransactionsFilterComponent } from './transactions-filter/transactions-filter.component';

@NgModule({
  declarations: [FinanceHistoryPageComponent],
  imports: [
    CommonModule,
    DateFnsModule,
    MobileModalModule,
    ModalModule,
    PaginationModule,
    PaginatorModule,
    SpinnersModule,
    TranslateModule,
    NoContentStubComponent,
    OnUiButtonModule,
    OnUiSelectModule,
    FormsModule,
    ReactiveFormsModule,
    PaymentHistoryModule,
    FilterContentComponent,
    TransactionsFilterComponent,
    OnUiDateModule,
    FormatPipeModule,
    IconComponent,
  ],
  exports: [FinanceHistoryPageComponent],
})
export class FinanceHistoryPageModule {}
