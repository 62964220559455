import { Component, EventEmitter, Output } from '@angular/core';
import { AlertModule } from '@core/components/alert/alert.module';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from '@shared/icon/icon.component';
import { OnUiButtonModule } from 'onclass-ui';

@Component({
  selector: 'app-alert-payout',
  standalone: true,
  templateUrl: './alert-payout.component.html',
  imports: [AlertModule, IconComponent, OnUiButtonModule, TranslateModule],
})
export class AlertPayoutComponent {
  @Output() cancel = new EventEmitter<boolean>();
  @Output() change = new EventEmitter<boolean>();

  constructor() {}

  public onCancel() {
    this.cancel.emit(true);
  }

  public onChange() {
    this.change.emit(true);
  }
}
