<div class="history {{type}}">
  <ng-container *ngIf="!deviceService.isMobile(), else mobileCard">
  <div class="table-wraper">
      <div class="header">
        <div class="cell date">{{'transaction-history.date' | translate}}</div>
        <div class="cell operation">{{'transaction-history.operation' | translate}}</div>
        <div class="cell amount">{{'transaction-history.amount' | translate}}</div>
        <div class="cell status">{{'transaction-history.status' | translate}}</div>
      </div>
    <div class="body" *ngIf="history">
      <div class="row" *ngFor="let operation of history; trackBy: trackByFn">
        <div class="cell date">
          {{getDateFromTransaction(operation.createdAt) | dfnsFormat: 'dd MMMM'}}<br>
          {{getDateFromTransaction(operation.createdAt) | dfnsFormat: 'HH:mm'}}
        </div>
        <div class="cell operation">
          <div class="title">{{'transaction-history.types.' + operation.type | translate}}</div>
          <div class="description">{{operation.operationDesc}}</div>
        </div>
        <div class="cell amount">
          {{formatAmount(operation)}}
        </div>
        <div class="cell status"><span class="completed">{{'transaction-history.statuses.completed' | translate}}</span></div>
      </div>
    </div>
  </div>
  </ng-container>
  <ng-template #mobileCard>
    <div class="mobile-list-wrapper" *ngIf="history">
      <div class="card" *ngFor="let operation of history; trackBy: trackByFn">
        <div class="line-1">
          <div class="data">
            <label>{{'transaction-history.date' | translate}}</label>
            <p class="date">
              {{getDateFromTransaction(operation.createdAt) | dfnsFormat: 'dd MMMM'}} {{getDateFromTransaction(operation.createdAt) | dfnsFormat: 'HH:mm'}}
            </p>
          </div>

          <div class="main-info">
            <div class="data">
              <label>{{'transaction-history.amount' | translate}}</label>
              <p class="amount">
                {{formatAmount(operation)}}
              </p>
            </div>
            <div class="data">
              <label>{{'transaction-history.status' | translate}}</label>
              <div class="status">
                <span class="completed">
                  {{'transaction-history.statuses.completed' | translate}}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="line-2">
          <div class="data">
            <label>{{'transaction-history.operation' | translate}}</label>
            <div class="operation">
              <div class="title">{{'transaction-history.types.' + operation.type | translate}}</div>
              <div class="description">{{operation.operationDesc}}</div>
            </div>
          </div>
        </div>
      </div>
      </div>
  </ng-template>
</div>
